function makeObjectsExtensible(arr, objId, data) {
  return arr.Nodes.map((item) => {
    // Make each object extensible
    let extensibleItem = { ...item };
    if (parseInt(extensibleItem.id,10) === parseInt(objId, 10)) {
      extensibleItem = { ...extensibleItem, Nodes: [...data] };
    }
    // Make the Nodes array extensible (recursively)
    if (Array.isArray(extensibleItem.Nodes)) {
      extensibleItem.Nodes = makeObjectsExtensible(extensibleItem, objId, data);
    }
    return extensibleItem;
  });
}

function findObjects(arr, objId, data) {
  return arr.Nodes.map((item) => {
    // Make each object extensible
    if (item.id === parseInt(objId, 10)) {
      item = { ...item, Nodes: [...data] };
    }
    // Make the Nodes array extensible (recursively)
    if (Array.isArray(item.Nodes)) {
      item.Nodes = findObjects(item, objId, data);
    }
    return item;
  });
}

function makeObjectsExtensibleDynamic(arr, objId, data, key) {
  return arr.Nodes.map((item) => {
    // Make each object extensible
    let extensibleItem = { ...item };
    if (parseInt(extensibleItem[key],10) === parseInt(objId, 10)) {
      extensibleItem = { ...extensibleItem, Nodes: [...data] };
    }
  
    // Make the Nodes array extensible (recursively)
    if (Array.isArray(extensibleItem.Nodes)) {
      extensibleItem.Nodes = makeObjectsExtensibleDynamic(extensibleItem, objId, data, key);
    }
    return extensibleItem;
  });
}

function makeObjectsTraversing(arr, data, folderId) {
  return arr.Nodes.map((item) => {
    // Make each object extensible
    let extensibleItem = { ...item };
    if (extensibleItem.id === parseInt(folderId, 10)) {
      extensibleItem = { ...extensibleItem, Nodes: [...data] };
    }
    // Make the Nodes array extensible (recursively)
    if (Array.isArray(extensibleItem.Nodes)) {
      extensibleItem.Nodes = makeObjectsTraversing(extensibleItem, data, folderId);
    }
    return extensibleItem;
  });
}

export { makeObjectsExtensible, findObjects, makeObjectsExtensibleDynamic, makeObjectsTraversing };
