/* eslint-disable no-unused-vars */
import { call, put, take, takeEvery, select, all, takeLatest } from 'redux-saga/effects';
import axiosInstance from '../components/jwt/axios';
import * as cutomiser from '../store/customizer/CustomizerSlice';
import * as common from '../store/common/CommonSlice';
import { Commonservice } from '../service/commonService';
import { AppCache } from '../service/appCache';
import config from '../config/config';
import { ServerCache } from '../service/serverCache';
import { getSliceData } from '../store/customizer/CustomizerSlice';
import { errorCode, findWorkspaceDetails,downloadMultiple, sortNameArrayFilter, sortArray, createNewSelected,removeObjectFromArray } from '../helpers/commonHelpers';
import { findObjects, makeObjectsExtensible, makeObjectsExtensibleDynamic, makeObjectsTraversing } from '../hooks/utilities';
// import useAuth from '../components/authGurad/useAuth';
// import { findId } from '../hooks/utilities';



const url = `http://${window?.location?.host}`;
const FinalData = []
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUser(action) {

   try {
      const user = yield call(axiosInstance, action.payload.userId);
      yield put({ type: "USER_FETCH_SUCCEEDED", user });
   } catch (e) {
      yield put({ type: "USER_FETCH_FAILED", message: e.message });
   }
}
function* fetchProfileInfo() {
   let user = null
   try {
      user = yield Commonservice.getProfileInfo();
      yield put(cutomiser.UpdateProfileInfo(user));
   } catch (e) {
      yield put(cutomiser.UpdateProfileInfo(null));
   }
   return user;

}

function* fetchPortalUserEndpoint() {
   try {
      const responseData = yield Commonservice.getPortalUserEndpoint();
      if (responseData.length > 0) {
         yield put(cutomiser.UpdatePortelUser(responseData));
      } else {
         yield put(cutomiser.updateEmptyPortalAlert(true));
         yield put(cutomiser.UpdatePortelUser([]));
      }
   } catch (e) {
      yield put(cutomiser.UpdatePortelUser([]));
      yield put(cutomiser.updateEmptyPortalAlert(true));
   }
}

function* fetchTableHeader(action) {
   const { TableName, id } = action.payload
   try {
      const responseData = yield ServerCache.getCacheWithName(TableName, id);
      yield put(cutomiser.UpdateTableHeaderFunction({
         name: TableName,
         data: responseData?.cache
      }))

   }
   catch (e) {
      console.log(e)
      yield put(cutomiser.UpdateTableHeaderFunction([]));
   }
}

function TableHeaderConfigStore(action) {
   try {
      const data = action.payload
      ServerCache.postCache(data)
   }
   catch {
      console.log("Error!")
   }
}

function* fetchPortalUsersEndpoint(action) {
   yield put(cutomiser.updateCommonLoader(true))
   try {
      const responsePortalUsers = yield Commonservice.getAllPortalUsers(action?.payload)
      yield put(cutomiser.UpdatePortalMemberTableData(responsePortalUsers?.entries))
      yield put(cutomiser.updateCommonLoader(false))
   }

   catch (e) {
      console.log(e)
      yield put(cutomiser.UpdatePortalMembersUsers(null));
      yield put(cutomiser.UpdatePortalMembersPermission(null));
      yield put(cutomiser.UpdateAllowedClients(null));
      yield put(cutomiser.UpdateError({ status: true, message: e.toString(), modalOpen: true }))
      yield put(cutomiser.updateCommonLoader(false));

   }
}

function* DeletePortalMember(action) {
   try {
      const portal = yield select(getSliceData)
      const selectedPortalId = portal?.payload?.customizer?.selectedportal?.cache[0]?.id
      const { userId } = action?.payload
      yield Commonservice.deletePortalMember(userId,selectedPortalId)
      yield put(cutomiser.UpdateconfirmDialogStatus({ status: false }))
      yield put(cutomiser.UpdatePortalId(selectedPortalId))
   }
   catch (e) {
      yield put(cutomiser.UpdateError({ status: true, message: e.toString(), modalOpen: true }))
   }
}

function* fetchInvitationEndpoint(action) {
   yield put(cutomiser.updateCommonLoader(true))
   try {
      const resposneInviteUser = yield Commonservice.getInvitationsEndpoint(action.payload)
      yield put(cutomiser.UpdateInvitationData(resposneInviteUser))
      yield put(cutomiser.updateCommonLoader(false))
   }
   catch (e) {
      console.log(e)
      yield put(cutomiser.UpdateInvitationData(null))
      yield put(cutomiser.updateCommonLoader(false))
   }
}

function fetchSidebar(action) {
   console.log('saga call');
}
function cacheUpdateselectedportal(action) {
   const cacheName = config.cachNamePortel;
   const response = action?.payload;
   AppCache.addDataIntoCache(cacheName, url, response);

}


function* cachedPortal(id) {
   try {
      const cacheName = config.cachNamePortel;
      const slice = yield select(getSliceData)
      const portalUser = slice?.payload?.customizer.portalUser;

      const myPortal = yield ServerCache.getCacheWithName(cacheName, id);
      const cachedPortalisPresentCurrentlyInUserPortals = portalUser?.some((item) => item.id === myPortal?.cache[0]?.id);
      if (myPortal && myPortal?.cache.length > 0 && cachedPortalisPresentCurrentlyInUserPortals) {
         yield put(cutomiser.Updateselectedportal(myPortal));
         yield put(cutomiser.UpdateLoggedUserPortalPermissionTrigger(myPortal?.cache[0]?.id))
      } 
      else if(portalUser?.length > 1){
         yield put(cutomiser.UpdateswitchPortalStatus(true));
      }
      else if(portalUser?.length === 1) {
         yield put(cutomiser.UpdateSwitchPortalCache({
            userid : id,
            cacheName : config.cachNamePortel,
            cache : portalUser[0],
         }))

      }
      else {
         yield put(cutomiser.updateEmptyPortalAlert(true))
      }
   }
   catch {
      yield put(cutomiser.Updateselectedportal({}));
      yield put(cutomiser.UpdateswitchPortalStatus(true));
      console.log("Error!")
   }
}

function* loadWeatherData(user) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const portal = yield select(getSliceData)
      const SelectedCity =  user?.payload?.locale?.location || portal?.payload?.customizer?.SelectedCity 
      const profileInfo = portal?.payload?.customizer?.profileInfo
      const weatherData = yield Commonservice.loadWeatherInfo(SelectedCity,profileInfo?.locale?.lang);
      yield put(cutomiser.UpdateCurrentWeather(weatherData))
      yield put(cutomiser.updateCommonLoader(false))
   }
   catch (e) {
      console.log("Failed")
      yield put(cutomiser.updateCommonLoader(true))
   }
}

function* cacheUpdateselectedLanguage(action) {
   const portal = yield select(getSliceData)
   const cacheName = config.cacheLang;
   const response = action?.payload
   AppCache.addDataIntoCache(cacheName, url, response)
}

function* cachedLanguage(user) {
   try {
         yield put(cutomiser.UpdateCurrentLanguage(user?.locale?.lang))
   }
   catch {
      console.log("Cached Language Error")
   }
}
function* loadLanguageData(languageselect = 'en') {
   try {

      const languageDataServer = yield ServerCache.getLanguageCache();
      const testData = yield ServerCache.getCacheWithName('test', 'kijkjkller');
      const languageData = yield Commonservice.loadLanguageInfo();
      const result = languageData.includes(languageselect)
   }
   catch (e) {
      console.log('Failed')
   }
}

function cacheUpdateAcceptedCookies(action) {
   const cachedAcceptedCookiesState = config.IccordinatorAppCache.acceptcookiescache;
   const response = action?.payload
   AppCache.addDataIntoCache(cachedAcceptedCookiesState, url, response)
}

function* cachedAcceptedCookies() {
   try {
      const cachedAcceptedCookiesState = config.IccordinatorAppCache.acceptcookiescache;
      const cookieState = yield AppCache.getSingleCacheData(cachedAcceptedCookiesState, url)

      if (!cookieState?.cookieState) {
         yield put(cutomiser.UpdateCookiesAccept({
            cookieState: false,
            popupState: true
         }))
      }

   }
   catch {
      console.log("cached Cookies Error")
   }
}

function* cacheUpdateDriveSidebar(action) {
   const response = action?.payload
   const cachedDriveSidebarState = config.IccordinatorAppCache.drivesidebarcache
   try {
      yield put(cutomiser.AddToDriveSidebarDataCache(response))
      ServerCache.postCache()
      AppCache.addDataIntoCache(cachedDriveSidebarState, url, response)
   }
   catch {
      console.log("this is the response of drive sidebar cache", response)
   }
}


function* cachedDriveSidebar() {
   try {
      yield put(cutomiser.UpdateLoading(true))
      const cachedDriveSidebarState = config.IccordinatorAppCache.drivesidebarcache
      const driveSidebarState = yield AppCache.getSingleCacheData(cachedDriveSidebarState, url)
      if (driveSidebarState?.pivotStarter) {

         yield put(cutomiser.UpdateDriverStarterPivotWithoutSaga({
            pivotData: driveSidebarState.pivotData,
            pivotStarter: driveSidebarState.pivotStarter,
            url: driveSidebarState.url
         }))
         yield put(cutomiser.UpdateLoading(false))
      } else {
         yield put(cutomiser.UpdateDriverStarterPivotWithoutSaga({
            pivotData: '',
            pivotStarter: '',
            url: ''
         }))
         yield put(cutomiser.UpdateLoading(false))
      }
   }
   catch {
      yield put(cutomiser.UpdateDriverStarterPivotWithoutSaga({
         pivotData: '',
         pivotStarter: '',
         url: ''
      }))
      yield put(cutomiser.UpdateLoading(false))
      console.log("cached Drive Sidebar Error")
   }
}



function* cachedAdminSidebar() {
   try {
      const cachedAdminSidebarState = config.IccordinatorAppCache.adminsidebarcache
      const adminsidebarState = yield AppCache.getSingleCacheData(cachedAdminSidebarState, url)

      if (!adminsidebarState?.pivotState) {
         yield put(cutomiser.UpdateAdminStarterPivot({
            pivotData: '433/5',
            pivotStarter: 433,
            url: 'user/admin/workspace/workspacedetails/5/Workspace1'
         }))
      }
   }
   catch {
      console.log("cached Admin Sidebar Error")
   }
}
function cacheUpdateAdminSidebar(action) {
   const cachedPeopleSidebarState = config.IccordinatorAppCache.adminsidebarcache
   const response = action?.payload
   AppCache.addDataIntoCache(cachedPeopleSidebarState, url, response)
}
function* fetchQuickViewSmartFolders(action) {
   const { cacheName, userid } = action.payload
   try {
      const QuickviewSmartFolders = yield ServerCache.getCacheWithName(cacheName, userid)
      if (QuickviewSmartFolders?.cache.length > 0) {
         const response = yield put(cutomiser.UpdateSmartFolderTree(QuickviewSmartFolders.cache))
      }
   }
   catch {
      console.log("Error")
   }
}
function* addQuickViewSmartFolders(action) {
   try {
      const response = action?.payload;
      ServerCache.postCache(response)
      yield put(cutomiser.initialAddQuickViewSmartFolders(response))
   }
   catch {
      console.log("An Error Occured!")
   }
}
function* putQuickViewSmartFolders(action) {
   try {
      const response = action.payload;
      const { userid, cacheName } = response
      ServerCache.putCache(userid, response, cacheName)
      yield put(cutomiser.initialAddQuickViewSmartFolders(response))
   }
   catch {
      console.log("Error")
   }
}
function* switchPortalconfig(action) {
   const data = action.payload
   const { cache, ...rest } = data
   const newdata = {
      cache: [cache],
      ...rest
   }
   try {
      yield put(cutomiser.AddDataToSwitchPortal(newdata))
      ServerCache.postCache(newdata)
   }
   catch {
      console.log("Error")
   }
}
/* Call post request */
function* Profileinfoload(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const { id, ...content } = action.payload;
      const response = yield Commonservice.putProfileInfo(content, id);
      if (response?.data) {
         yield put(cutomiser.updateProfileState({ status: "success" }))
         const payload = { 'status': true, message: { body: "Profile_info_success", defaultMessage: "Profile info updated successfully.", head: 'profile_info_head' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         yield put(cutomiser.UpdateProfileInfo(response?.data))
         yield put(cutomiser.updateCommonLoader(false))
      }
      if (content?.password && response?.data) {
         const payload = { 'status': true, message: { body: "reset_password", defaultMessage: "Password reset successfully", head: 'reset_password_head' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         yield put(cutomiser.updateCommonLoader(false))
      }
   } catch (e) {
      yield put(cutomiser.updateProfileState({ status: "false", statusCode: e?.response?.status }))
      yield put(cutomiser.updateCommonLoader(false))
   }
}

function putTableHeaderConfigConfiguration(action) {
   try {
      const response = action.payload;
      const { userid, cacheName } = response
      ServerCache.putCache(userid, response, cacheName)
   }
   catch {
      console.log("Error")
   }
}

function* fetchMembersData(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const { userid } = action?.payload;
      const userData = yield Commonservice.getWorkspacePermissionsEndpoint(userid);
      yield put(cutomiser.addToMemberData(userData));
      yield put(cutomiser.updateCommonLoader(false))
   }
   catch {
      console.log("Error")
      yield put(cutomiser.updateCommonLoader(false))
   }
}

function* fetchUserDetailsForMembers(action) {
   yield put(cutomiser.UpdateLoading(true))
   try {
      const { userid } = action?.payload;
      const userMemberData = yield Commonservice.getPortalUsersEndpoint(userid);
      yield put(cutomiser.addToUserMemberDetails(userMemberData));
      yield put(cutomiser.UpdateLoading(false))
   }

   catch {
      console.log("Error")
      yield put(cutomiser.UpdateLoading(false))
   }
}

function* fetchWorkspaceMenu(action) {
   try {
      const portalId = action?.payload;
      const responseData = yield Commonservice.getAllWorkspacesListEndpoint(portalId);
   }
   catch {
      console.log("Error")
   }
}

function* putSwitchPortalContent(action) {
   try {
      const response = action.payload;
      const { userid, cacheName } = response
      //This getcache and state need to identical
      const cache = [response.cache];
      const portelState = { userid, cacheName, cache }
      yield put(cutomiser.AddDataToSwitchPortal(portelState))
      ServerCache.putCache(userid, response, cacheName)
   }
   catch {
      console.log("Error")
   }
}
function* fetchgetHistoryEndpoint(action) {
   yield put(cutomiser.UpdateLoading(true))
   const {portalId,...queryObj} = action.payload
   try {
      const query = Object.entries(queryObj).filter(([key, value]) => value).map(([key, value]) => `${key}=${value}`).join('&')
      const responseData = yield Commonservice.getHistoryEndpoint(query,portalId);
      /* eslint-disable camelcase */
      yield put(cutomiser.updateDashboardData(responseData));
      yield put(cutomiser.UpdateLoading(false))
   }
   catch(e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "activity_history", body:error, defaultMessage: "Network error." } }))
      yield put(cutomiser.UpdateLoading(false))
   }
}



function* fetchCache(action) {
   const { cacheName, userid } = action.payload
   try {
      const cachedMemeberMenuState = yield ServerCache.getCacheWithName(cacheName, userid)
      yield put(cutomiser.membersLeftMenuCacheonLoad(cachedMemeberMenuState))
   }
   catch {
      yield put(cutomiser.membersLeftMenuCacheonLoad([]))
      console.log("Error")
   }
}

function* addCache(action) {
   try {
      const response = action?.payload;
      ServerCache.postCache(response)
      yield put(cutomiser.membersLeftMenuCacheonLoad(response))
   }
   catch {
      console.log("An Error Occured!")
   }
}

function* putCache(action) {
   try {
      const response = action.payload;
      const { userid, cacheName } = response
      ServerCache.putCache(userid, response, cacheName)
      yield put(cutomiser.membersLeftMenuCacheonLoad(response))
   }
   catch {
      console.log("Error")
   }
}
function* putSingleAllowedClientData(action) {
   try {
      const { portalId, userId, content } = action.payload;
      Commonservice.putSingleAllowedClient(portalId, userId, content);
      yield
   }
   catch (e) {
      console.log("error!")
   }
}





function* fetchWorkspacePortal(action) {
   yield put(cutomiser.updateSideBarLoader(true))
      try {
         const portalId = action?.payload;
         const responseData = yield Commonservice.getWorkspacesNew(portalId)
         if (responseData) {
            yield put(cutomiser.updateAdminSideBar(responseData))
         } 
   yield put(cutomiser.updateSideBarLoader(false))
   } catch (e) {
      yield put(cutomiser.updateSideBarLoader(false))
   }
}

function* fetchLabelData(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const portalId = action?.payload;
      const metaData = yield Commonservice.getPortalMetafieldEndpoint(portalId);
      if (metaData) {
         yield put(cutomiser.UpdateLabelData(metaData))
      }
      else {
         yield put(cutomiser.UpdateLabelData({}))
      }
      yield put(cutomiser.updateCommonLoader(false))
   }
   catch (e) {
      console.log("error!")
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.UpdateError({ status: true, message: error, modalOpen: true }))
      yield put(cutomiser.UpdateLabelData(null))
      yield put(cutomiser.updateCommonLoader(true))
   }
}

function* fetchAdminSidebarData(action) {
   const { userId, cacheName } = action.payload;
   yield put(cutomiser.udpateAdminCacheLoader(true))
   try {
      const responseData = yield ServerCache.getCacheWithName(cacheName, userId)
      if (responseData.cache.length > 0) {
         yield put(cutomiser.adminLeftMenuCacheonLoad(responseData))
      }
   yield put(cutomiser.udpateAdminCacheLoader(false))

   }
   catch (e) {
      yield put(cutomiser.udpateAdminCacheLoader(false))
      console.log("Error!")
   }
}
function* FetchAllowedClients(action) {
   yield put(cutomiser.UpdateLoading(true))
   try {
      const { portalID } = action?.payload
      const AllowedClientData = yield Commonservice.getPortalAllowedClientsEndpoint(portalID)
      yield put(cutomiser.UpdateAllowedClients(AllowedClientData))
      yield put(cutomiser.UpdateLoading(false))
   }
   catch (e) {
      console.log("error!")
      yield put(cutomiser.UpdateLoading(false))
   }
}

function* GetAllGroupsData(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const { workspaceId } = action.payload
      const AdminGroupData = yield Commonservice.getWorkspaceGroupsEndpoint(workspaceId)
      if (AdminGroupData?.entries?.length > 0) {
         yield put(cutomiser.UpdateGroupData(AdminGroupData?.entries))
      }
      else {
         yield put(cutomiser.UpdateGroupData([]))
      }
      yield put(cutomiser.updateCommonLoader(false))
   }
   catch (e) {
      console.log("Error!")
   }
}


function* PostMenuAdminLeft(action) {
   try {
      const response = action.payload
      ServerCache.postCache(response)
      yield put(cutomiser.adminLeftMenuCacheonLoad(response))
   }
   catch (e) {
      console.log("Error!")
   }
}

function* PutMenuAdminLeft(action) {
   try {
      const response = action.payload
      const { userid, cacheName } = response
      ServerCache.putCache(userid, response, cacheName)
      yield put(cutomiser.adminLeftMenuCacheonLoad(response))
   }
   catch (e) {
      console.log("Error!!")
   }
}

// Member Admin Sidebar
function* FetchMemberSidebarCache(action) {
   try {
      const response = action?.payload
      const { userid, cacheName } = response
      const MemberSidebarData = yield ServerCache.getCacheWithName(cacheName, userid)
      yield put(cutomiser.membersLeftMenuCacheonLoad(MemberSidebarData))
   }
   catch (e) {
      console.log("Error!")
   }
}

function* AddMemberSidebarCache(action) {
   try {
      const response = action.payload
      ServerCache.postCache(response)
      yield put(cutomiser.membersLeftMenuCacheonLoad(response))
   }
   catch (e) {
      console.log("Error!!")
   }
}

function* PutMemberSidebarCache(action) {
   try {
      const response = action.payload
      const { userid, cacheName } = response
      ServerCache.putCache(userid, response, cacheName)
      yield put(cutomiser.membersLeftMenuCacheonLoad(response))
   }
   catch (e) {
      console.log("Error!!")
   }
}
//Members Home 
function* fetchMemberHomeData(action) {
   const portal = yield select(getSliceData)
   const membersState = portal?.payload?.customizer?.HomeMembersData
   try {
      if(membersState?.length === 0){
         yield put(common.updateHomeMemberLoadingState(true))
      }
      const PortalMembersData = yield Commonservice.getPortalUsersEndpoint(action.payload);
      const { has_more, next_offset, entries } = PortalMembersData

      if (membersState?.entries?.length > 0) {
         yield put(cutomiser.UpdateHomeMemberData({
            has_more, next_offset, entries : [...membersState.entries,...entries]
         }))
      }
      else{
         yield put(cutomiser.UpdateHomeMemberData({
            has_more, next_offset, entries
         }))
      }
      yield put(common.updateHomeMemberLoadingState(false))
   }
   catch (e) {
      yield put(common.updateHomeMemberLoadingState(false))
      console.log("error!")
   }
}

function* GetFolderDetails(action) {
   try {
      const FolderId = action?.payload
      const response = yield Commonservice.getFolderDetail(FolderId)
      yield put(cutomiser.UpdateSingleFolderDetail(response))
   }
   catch (e) {
      console.log("Error!!")
   }
}
function* PutSingleAllowedClients(action) {
   try {
      const { portalId, userId, content } = action?.payload;
      const response = yield Commonservice.putSingleAllowedClient(portalId, userId, content);
      const payload = { 'status': true, message: { body: "allowed_clients_updated_successfully", defaultMessage: "Allowed clients updated successfully", head: 'Allowed clients' } }
      yield put(cutomiser.updateToastDialogModal(payload));
      if (portalId !== undefined || null) {
         yield put(cutomiser.UpdatePortalId(portalId))
      }
      yield put(cutomiser.updateModalChangeStatus(false))
   }
   catch (e) {
      const error = errorCode(e.response.status)
      yield put(cutomiser.UpdateError({
         status: true,
         message: error,
         modalOpen: true
      }))
      console.log("Error!!")
   }
}
function* GetFileDetails(action) {
   try {
      const FileId = action?.payload
      const response = yield Commonservice.getFileDetail(FileId)
      yield put(cutomiser.UpdateSingleFileDetail(response))
   }
   catch (e) {
      console.log("Error!!")
   }
}

function* PermissionLoggedUser(action) {
   try {
      const allUsers = yield Commonservice.getAllPortalUsers(action?.payload)
      const user = yield fetchProfileInfo();
      const finalData = allUsers?.entries?.filter((item) => item.id === user.id)
      yield put(cutomiser.UpdatePortalMemberTableData([...allUsers?.entries]))
      yield put(cutomiser.updateLoggedInUserPermissionState(finalData[0]?.permissions[0]?.actions ? finalData[0]?.permissions[0]?.actions : []))
   }
   catch (e) {
      yield put(cutomiser.UpdateError({ status: true, message: "error" }))
      console.log("Error!!")
   }
}



function* cachedAppDateTimeFormat(user) {
   try {
      const dateFormat = user?.locale?.date_format;
      const timeFormat = user?.locale?.time_format;
      if (dateFormat !== '' && timeFormat !== '') {
         yield put(cutomiser.UpdateAppDateTimeFormat({
            dateFormat, timeFormat
         }))
      }
   }
   catch (e) {
      console.log(e)
   }
}




/* Call all app initiating request */
function* bootrsapApp(action) {
   try {
      yield put(cutomiser.updateBootsrapLoader(true));
      const user = yield fetchProfileInfo();
      yield fetchPortalUserEndpoint();
      if (user !== null) {
         yield cachedLanguage(user);
         yield cachedPortal(user.id);
         yield loadWeatherData(user);
         yield cachedAppDateTimeFormat(user);
         yield cachedAcceptedCookies();
         yield cachedDriveSidebar();
         yield cachedAdminSidebar()
         yield cachedAdminSidebar();
         // yield PermissionLoggedUser();
      }

      yield put(cutomiser.updateBootsrapLoader(false));
   } catch (e) {
      console.log('failed');
      yield put(cutomiser.updateBootsrapLoader(false));
   }
}

function* fetchRecyclbinChildren(action) {
   const workspaceId = action.payload;
   try {
      yield put(cutomiser.updateDriveLoading(true));
      const response = yield Commonservice.getWorkspaceTrashChildrenEndpoint(workspaceId)
      yield put(cutomiser.PutRecyclbinChildren(response))
      yield put(cutomiser.updateDriveLoading(false));
   }
   catch (e) {
      yield put(cutomiser.updateDriveLoading(false));
      console.log("Error!!")
   }
}
function* updateProfilePhoto(action) {
   yield put(cutomiser.updateCommonLoader(true))
   try {
      const {token, formData } = action.payload;
      const response = yield Commonservice.updateProfilePic(formData, token )
      if (response) {
         yield put(cutomiser.UpdateProfileInfo(response?.data));
      }
      if (response.status === 201) {
         const payload = { 'status': true, message: { body: "Profile_picture", defaultMessage: "Profile picture updated", head: 'success_head' } }
         yield put(cutomiser.updateToastDialogModal(payload));
      }
      yield put(cutomiser.updateCommonLoader(false))

   } catch (e) {
      yield put(cutomiser.UpdateError({ status: true }))
      yield put(cutomiser.updateCommonLoader(false))

   }
}

function* fetchActivityEndpoint(action) {
   yield put(cutomiser.UpdateLoading(true))
   const {portalId,...queryObj} = action.payload
   try {
      const query = Object.entries(queryObj).filter(([key, value]) => value).map(([key, value]) =>`${key}=${value}`).join('&')
      const responseData = yield Commonservice.getHistoryEndpoint(query,portalId);
      /* eslint-disable camelcase */
      const { next_offset, has_more, entries } = responseData;
      const portal = yield select(getSliceData)
      const prevData = portal?.payload?.customizer?.activityData
      if(entries){
         const x = prevData.data?prevData.data:[]
      yield put(cutomiser.AddActivityData({data:[...x,...entries],lastOffset:next_offset,hasMore:has_more}))
      yield put(cutomiser.AddActivityOffset({ last_offset:next_offset, has_more }))
      }
      if(entries?.length===0 && has_more){
         yield put(cutomiser.FetchActivityData({...action.payload,offset:next_offset}))
      }
      yield put(cutomiser.UpdateLoading(false))
   }
   catch(e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "activity_history", body:error, defaultMessage: "Network error." } }))
      yield put(cutomiser.UpdateLoading(false))
   }
}
function* CreateFavSmartFolderCache(action) {
   try {
      const response = action.payload
      const { userid, cacheName } = action.payload
      try{
         const servResp = yield ServerCache.postCache(response)
         const payload = { 'status': true, message: { body: "Smart_folder_add_success", defaultMessage: "successfully added favorite smart folder", head: 'Favorite_smart_folders' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         yield put(cutomiser.GetFavSmartFolderCache({
            userid, cacheName
         }))
         yield put(cutomiser.ReadFavSmartFolderCache(servResp?.data[0]?.cache))

      }
      catch(e){
         yield put(cutomiser.UpdateError({
            status: true,
            message: 'favorite_smartfolder_error',
            modalOpen: true
         }))
      }
     
   }
   catch (e) {
      console.log("Error!!")
   }
}

function* updateFavSmartFolderCacheItem(action) {
   try {
      const response = action.payload;
      const { userid, cacheName, cache } = response;
      try{
         const serResp = yield ServerCache.putCache(userid, response, cacheName);
       
         const responseData = serResp?.data[0]?.cache[0].length ? serResp?.data[0]?.cache: [];
         const payload = { 'status': true, message: { body: "favorite_smartfolder_updation_success", defaultMessage: "Successfully updated favorite smart folder", head: 'Favorite_smart_folders' } }
            yield put(cutomiser.updateToastDialogModal(payload));
            yield put(cutomiser.GetFavSmartFolderCache({
               userid, cacheName
            }))
      }
      catch(e){
         yield put(cutomiser.UpdateError({
            status: true,
            message: 'favorite_smartfolder_error',
            modalOpen: true
         }))
      }
         
   }
   catch (e) {
      console.log("Error!!")
   }
}




function* getFavSmartFolderCacheItems(action) {
   try {
      const { cacheName, userid } = action.payload
      yield put(cutomiser.updateHomesmartFolderLoader(true))
      const response = yield ServerCache.getCacheWithName(cacheName, userid);
      if (response?.length !== 0 || !undefined) {
         const responseData = response?.cache[0].length ? response?.cache: [];
         yield put(cutomiser.ReadFavSmartFolderCache(response?.cache))
         yield put(cutomiser.updateHomesmartFolderLoader(false))
      }
      else {
         yield put(cutomiser.ReadFavSmartFolderCache([]))
         yield put(cutomiser.updateHomesmartFolderLoader(false))
      }
      yield put(cutomiser.updateHomesmartFolderLoader(false))
   }
   catch (e) {
   yield put(cutomiser.updateHomesmartFolderLoader(false))
      console.log("Error!!")
   }
}

function* getWorkspaceGroupList(action) {
   const { workspaceId } = action.payload
   try {
      const responseData = yield Commonservice.getWorkspaceGroupsEndpoint(workspaceId)
      FinalData.push({ workspaceId, groups: responseData?.entries })
      yield put(cutomiser.updateWorkspaceGroups([...FinalData]))
      // yield put(cutomiser.updateWorkspaceGroups(responseData?.entries))
   }
   catch (e) {
      console.log("Error!")
   }
}


function* postInviteMembers(action) {
   const content = action.payload;
   const portal = yield select(getSliceData)
   const selectedPortalId = portal?.payload?.customizer?.selectedportal?.cache[0]?.id
   let memberListInvite = portal?.payload?.customizer?.memberListInvite
   let memberInviteSuccess = portal?.payload?.customizer?.memberInviteSuccess
   let memberInviteError = portal?.payload?.customizer?.memberInviteError

   memberListInvite = [...memberListInvite, { firstname: content?.first_name, lastname: content?.last_name }]
   yield put(cutomiser.updatememberListInvite(memberListInvite))

   try {
      yield put(cutomiser.updateCommonLoader(true))
      for (let i = 0; i < content?.length; i++) {
         const response = yield Commonservice.postInvite(content[i])
         if (response.status !== 202) {
            memberInviteError = [...memberInviteError, { firstname: content[i]?.first_name, lastname: content[i].last_name }]
            yield put(cutomiser.updateCommonLoader(true))
            yield put(cutomiser.updatememberInviteError(memberInviteError))
         }
         if (response.status === 202) {
            memberInviteSuccess = [...memberInviteSuccess, { firstname: content[i].first_name, lastname: content[i].last_name }]
            yield put(cutomiser.updatememberInviteSuccess(memberInviteSuccess))
            yield put(cutomiser.UpdateInvitation(selectedPortalId))
            yield put(cutomiser.updateCommonLoader(false))
         }
      }
      const payload = { 'status': true, message: { body: `An invitation has now been sent to`, defaultMessage: `An invitation has now been sent to`, head: 'Invitations', successInviteMembers: memberInviteSuccess, failedInviteMembers: memberInviteError } }
      yield put(cutomiser.updateAdminInvitationToastState(payload));
      if (selectedPortalId !== undefined || null) {
         yield put(cutomiser.UpdatePortalId(selectedPortalId))
         yield put(cutomiser.updatememberInviteError([]))
         yield put(cutomiser.updatememberInviteSuccess([]))
      }
   }
   catch (e) {
      yield put(cutomiser.updateCommonLoader(false))
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "invitation_failed", body:error, defaultMessage: "Invitation failed." } }))

      console.log("Error!")
   }
}

function* sendWorkSpaceDetails(action) {
   try {
      const portal = yield select(getSliceData)
      const data = action.payload
      try{
         const response = yield Commonservice.addWorkSpace(data)
         if (data?.defaultmember) {
            const profileInfo = portal?.payload?.customizer?.profileInfo
            const content = { grant_to: { entity_id: profileInfo?.id, entity_type: "user" }, actions: ["admin"] }
            try{
            yield Commonservice.defaultMemberinWorkspace(response?.id, content)
            if (response && portal?.payload?.customizer?.selectedportal?.cache[0]?.id) {
               yield put(cutomiser.FetchWorkspacesFromPortal(portal?.payload?.customizer?.selectedportal?.cache[0]?.id))
               const modalPayload = { 'status': true, message: { body: "new workspace", defaultMessage: "New work space created.", head: 'new workspace_header' } }
               yield put(cutomiser.updateToastDialogModal(modalPayload));
            }
            }
            catch(e){
               const error = errorCode(e.response.status)
               yield put(cutomiser.updateDriveError({ status: true, message: { head: "workspace_creation_failed", body:error, defaultMessage: "Error , Please try again" } }));
            }
         }
         else{
               yield put(cutomiser.FetchWorkspacesFromPortal(portal?.payload?.customizer?.selectedportal?.cache[0]?.id))
               const modalPayload = { 'status': true, message: { body: "new workspace", defaultMessage: "New work space created.", head: 'new workspace_header' } }
               yield put(cutomiser.updateToastDialogModal(modalPayload));
         }
        
      }
      catch(e){
            const error = errorCode(e.response.status)
            yield put(cutomiser.updateDriveError({ status: true, message: { head: "workspace_creation_failed", body:error, defaultMessage: "Error , Please try again" } }));
      }

   }
   catch (e) {
      const error = errorCode(e.status.response)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "workspace_creation_failed", body:error, defaultMessage: "Error , Please try again" } }));
   }
}

function* fetchWorkspacesRecursive(action) {
   yield put(cutomiser.updatePortalWorkspacesLoading(true))
   // try {
   //    const portalId = action?.payload;
   //    const responseData = yield Commonservice.getPortelFolders(portalId);
   //    // const recursiveWorkpsaceData = yield makeRecursiveWorkspaceData(responseData);
   //    if (responseData) {
   //       yield put(cutomiser.UpdateAdminWorkspaces(responseData));
   //    } 
   //    else {
   //       yield put(cutomiser.UpdateAdminWorkspaces([]));
   //    }
   //    yield put(cutomiser.updatePortalWorkspacesLoading(false))
   // } catch (e) {
   //    yield put(cutomiser.UpdateAdminWorkspaces(null));
   console.log("<<<<>>>>>>>>> IN RECURSIVE API CALL <<<<<<>>>>>>>>>>")
      yield put(cutomiser.updatePortalWorkspacesLoading(false))
   // }
}
function* getLabelItems(action) {

   try {
      const response = yield Commonservice.fetchLabel(action.payload);
      const { has_more, next_offset, entries } = response
      yield put(cutomiser.addLabelStore({ has_more, next_offset, entries }))
   }
   catch (e) {
      console.log(e);
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.UpdateError({
         status: true,
         message: error,
         modalOpen: true
      }))
   }
}
function* putBreadcrubDriveCache(action) {
   try {
      if (action.payload.method === 'update') {
         const response = action.payload;
         const { userid, cacheName, cache } = response;
         const serverResponse = yield ServerCache.putCache(userid, response, cacheName);
         
      } else {
         const response = action.payload
         const { userid, cacheName } = action.payload
         const responseReturn = yield ServerCache.postCache(response)
      }
   }
   catch (e) {
      console.log("Error!!")
   }
}

function* fetchBreadcrubDrive(action) {
   try {

      yield put(cutomiser.updateDriveCacheLoader(true))
      const { cacheName, userid, navigateFn } = action.payload
      const response = yield ServerCache.getCacheWithName(cacheName, userid);
      if(response?.cache?.length>0){
         yield put(cutomiser.updateBreadcrubDrive(response.cache[0]))
         yield put(cutomiser.addDriveSidebarCache(response))
      }else{
         navigateFn('/user/drive/workspace')
      }
      yield put(cutomiser.updateDriveCacheLoader(false))
   }
   catch (e) {
      console.log(e)
      yield put(cutomiser.updateDriveCacheLoader(false))
   }
}

function* DeleteInvitations(action) {
   try {
      const { inviteid } = action.payload
      const portal = yield select(getSliceData)
      const selectedPortalId = portal?.payload?.customizer?.selectedportal?.cache[0]?.id
      const resp = yield Commonservice.deleteInvitation(inviteid).then(response => {
         return response
      }).catch((e) => {
         return e
      })
      if (resp.status !== 204) {
         yield put(cutomiser.UpdateError({
            status: true,
            message: 'error_networkfailure',
            modalOpen: true
         }))
      }
      if (resp.status === 204) {
         const payload = { 'status': true, message: { body: "Invitation_removed_user", defaultMessage: "Invitation was removed successfully", head: 'Remove invitation' }, navigate: "" }
         yield put(cutomiser.updateToastDialogModal(payload));
         if (selectedPortalId !== undefined || null) {
            yield put(cutomiser.UpdateInvitation(selectedPortalId))
         }
         yield put(cutomiser.UpdateconfirmDialogStatus({ 'status': false }))
      }
   }
   catch (e) {
      yield put(cutomiser.UpdateError({ status: true, message: e.toString(), modalOpen: true }))
   }
}

function* workSpaceCopySent(action) {
   try {
      const portal = yield select(getSliceData)
      const { selectWorkspace, payload } = action.payload
      try{
         const response = yield Commonservice.putWorkSpaceCopy(selectWorkspace, payload)
         if (response && portal?.payload?.customizer?.selectedportal?.cache[0]?.id) {
            yield put(cutomiser.FetchWorkspacesFromPortal(portal?.payload?.customizer?.selectedportal?.cache[0]?.id))
            const modalPayload = { 'status': true, message: { body: "workspace copied", defaultMessage: "Workspace copied", head: 'workspace copied_header' } }
            yield put(cutomiser.updateToastDialogModal(modalPayload));
         }
      }
      catch(e){
         const error = errorCode(e?.response?.status)
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "permission_error_head", body:error, defaultMessage: "Error , Please try again" } }));
      }

   }catch (e) {
      yield put(cutomiser.UpdateError({ status: true, statusCode: e.response.status }))
   }
}

function* postRemoveWorkSpace(action) {
   try {
      const portal = yield select(getSliceData)
      const response = yield Commonservice.deleteWorkSpace(action.payload)
      if (response && portal?.payload?.customizer?.selectedportal?.cache[0]?.id) {
         yield put(cutomiser.FetchWorkspacesFromPortal(portal?.payload?.customizer?.selectedportal?.cache[0]?.id))
         const modalPayload = { 'status': true, message: { body: "Remove workspace successfull", defaultMessage: "Workspace removed successfully", head: 'Remove Workspace' }, navigate: "/user/admin/workspace" }
         yield put(cutomiser.updateToastDialogModal(modalPayload));
         const urlBread = "Admin/Workspaces"
         yield put(cutomiser.UpdateSidebarMenuActive(urlBread))
         yield put(cutomiser.UpdateconfirmDialogStatus({ 'status': false, navigate: "" }))
      }
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({
         status: true, message: {
            head: "Delete workspace failed", body: error, defaultMessage: error
         }
      }));
   }
}



function* resendInvitation(action) {
   try {
      const { selectedDetails } = action?.payload;
      const portal = yield select(getSliceData)
      let memberInviteSuccess = portal?.payload?.customizer?.memberInviteSuccess
      let memberInviteError = portal?.payload?.customizer?.memberInviteError
      for (let i = 0; i < selectedDetails?.length; i++) {
         const response = yield Commonservice.getResendInvitationEndpoint(selectedDetails[i]?.id)
         if (response.status === 204) {
            memberInviteSuccess = [...memberInviteSuccess, { firstname: selectedDetails[i]?.name }]
            yield put(cutomiser.updatememberInviteSuccess(memberInviteSuccess))
         }
         if (response.status !== 204) {
            memberInviteError = [...memberInviteError, { firstname: selectedDetails[i]?.name }]
            yield put(cutomiser.updatememberInviteError(memberInviteError))
         }
      }
      const payload = { 'status': true, message: { body: "An invitation has now been resent to the selected user(s)", defaultMessage: "An invitation has now been resent to the selected user(s)", head: 'invitation_resend_header', successInviteMembers: memberInviteSuccess, failedInviteMembers: memberInviteError } }
      yield put(cutomiser.updateAdminInvitationToastState(payload));
      yield put(cutomiser.updatememberInviteError([]))
      yield put(cutomiser.updatememberInviteSuccess([]))
   }
   catch (e) {
      console.log("Error")
   }
}
function* sentRenameWorkSpace(action) {
   try {
      const portal = yield select(getSliceData)
      const adminLeftSidebar  = portal?.payload?.customizer?.adminLeftMenuCache
      const profileInfo  = portal?.payload?.customizer?.profileInfo
      const adminSidebarCache = adminLeftSidebar?.cache
      const selectedPortal =  portal?.payload?.customizer?.selectedportal?.cache[0]?.id

      const { fields,workspaceId } = action.payload

      yield put(cutomiser.updateComponentCardName(fields.name))
      try{
         const response = yield Commonservice.putNameWorkSpace(action.payload)
         if(response.status === 200)
         {
            yield put(cutomiser.getMembersData({
             userid: workspaceId
            }))

            if(adminSidebarCache?.length < 1)
            {
               yield put(cutomiser.addMenuAdminLeft({
                  userid: profileInfo.id,
                  cache: [{
                      pivotData: adminSidebarCache[0]?.pivotData,
                      pivotStarter: adminSidebarCache[0]?.pivotStarter,
                      url: `/user/admin/workspace/workspacedetail/${workspaceId}/${response?.data?.name}`,//doubt in every module on appending id and key for special cases (delete the condition and only item.url in drive sidebar to identify the bug and switch tabs after accessing deep in to children),
                      type: 'Workspace',
                  }],
                  cacheName: `${config.IccordinatorAppCache.adminsidebarcache}_${selectedPortal}`,
                  key: `${profileInfo.id}_${config.IccordinatorAppCache.adminsidebarcache}_${selectedPortal}`
               }))
            }
            else{
               yield put(cutomiser.updateadminLeftMenuCache({
                  userid: profileInfo.id,
                  cache: [{
                      pivotData:adminSidebarCache[0]?.pivotData,
                      pivotStarter: adminSidebarCache[0]?.pivotStarter,
                      url: `/user/admin/workspace/workspacedetails/${workspaceId}/${response?.data?.name}`,
                      type: 'Workspace'
                  }],
                  cacheName: `${config.IccordinatorAppCache.adminsidebarcache}_${selectedPortal}`
              }))
          }
         }
         
         if (response && selectedPortal) 
         {
               yield put(cutomiser.FetchWorkspacesFromPortal(selectedPortal))
               const payload = { 'status': true, message: { body: "rename workspace", defaultMessage: "Rename workspace successful.", head: 'rename workspace_header' } }
               yield put(cutomiser.updateToastDialogModal(payload));
         }
      }
      catch(e){
         const error = errorCode(e?.response?.status)
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "Rename workspace failed", body:error, defaultMessage: "Error , Please try again" } }));
      }
      
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Rename workspace failed", body:error, defaultMessage: "Error , Please try again" } }));
   }
}
function* postDefaultSyncStatus(action) {
   try {
      const portal = yield select(getSliceData)
      const response = yield Commonservice.sendDefaultSync(action.payload)
      if (response && portal?.payload?.customizer?.selectedportal?.cache[0]?.id) {
         yield put(cutomiser.FetchWorkspacesFromPortal(portal?.payload?.customizer?.selectedportal?.cache[0]?.id))
         const modalPayload = { 'status': true, message: { body: "default sync changed", defaultMessage: "Default sync changed", head: 'default sync head' } }
         yield put(cutomiser.updateToastDialogModal(modalPayload));
      }
   } catch (e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.UpdateError({
         status: true,
         message: error,
         modalOpen: true
      }))
   }
}


function* updateWorkspacePermission(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const portal = yield select(getSliceData)
      const workspaceId = portal?.payload?.customizer?.membersDetails?.userid

      const { permissionId, content } = action.payload
      try{
         const response = yield Commonservice.changeWorkspacePermission(permissionId, content)
         const payload = { 'status': true, message: { body: "permission_has_been_changed", defaultMessage: "Permission has been changed successfully", head: 'permission_changed' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         if (workspaceId !== undefined || null) {
            yield put(cutomiser.getMembersData({
               userid: workspaceId
            }))
         }
         yield put(cutomiser.updateCommonLoader(false))
         yield put(cutomiser.updateModalChangeStatus(false))
      }
      catch(e){
         const error = errorCode(e?.response?.status)
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "Workspace permission", body:error, defaultMessage: "Unable to change permission." } }))

      }
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.UpdateError({
         status: true,
         message: error,
         modalOpen: true
      }))
      yield put(cutomiser.updateCommonLoader(true))
   }
}

function* AddLabel(action) {
   try {
      const portal = yield select(getSliceData)
      const selectedPortalId = portal?.payload?.customizer?.selectedportal?.cache[0]?.id
      const { content } = action.payload;
      try{
         const response = yield Commonservice.postLabel(selectedPortalId, content)
         if (response.status === 201) {
            const payload = { 'status': true, message: { body: "The metafield was successfully created.", defaultMessage: "Permission has been changed successfully", head: 'Label_Added' } }
            yield put(cutomiser.updateToastDialogModal(payload));
            if (selectedPortalId !== undefined || null) {
               yield put(cutomiser.GetLabelData(selectedPortalId))
            }
         }
      }
      catch(e){
            const error = errorCode(e?.response?.status)
            yield put(cutomiser.updateDriveError({ status: true, message: { head: "Label error", body:error, defaultMessage: "Unable to add new label." } }))
      }
   }
   catch (e) {
      yield put(cutomiser.UpdateError({
         status: true,
         message: 'error_networkfailure',
         modalOpen: true
      }))
   }
}
function* postUserWrkSpace(action) {
   try {
      const response = yield Commonservice.sendUserWorkSpace(action.payload)
      if (response) {
         yield put(cutomiser.getMembersData({ userid: action.payload.id }))
         const modalPayload = { 'status': true, message: { body: "user added to wrokspace", defaultMessage: "User added to workspace successfully", head: 'user added head workspace' } }
         yield put(cutomiser.updateToastDialogModal(modalPayload));
      }
   } catch (e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Add_members_failed", body:error, defaultMessage: "Failed to add members" } }))
   }
}
function* DeleteUserFromWorkspace(action) {
   try {
      const portal = yield select(getSliceData)
      const workspaceId = portal?.payload?.customizer?.membersDetails?.userid
      const { userId } = action.payload

      try{
         const response = yield Commonservice.deleteUserInWorkspace(workspaceId,userId)
         yield put(cutomiser.UpdateconfirmDialogStatus({ status: false }));
         const payload = { 'status': true, message: { body: "user_deleted_from_workspace", defaultMessage: "The user has been deleted successfully", head: 'Delete workspace user(s)' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         if (workspaceId !== undefined || null) {
            yield put(cutomiser.getMembersData({
               userid: workspaceId
            }))
         }
      }
      catch(e){
         const error = errorCode(e?.response?.status)
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "delete_op_failed", body:error, defaultMessage: "Delete Failed." } }))
      }
   }
   catch (e) {
      yield put(cutomiser.UpdateError({
         status: true,
         message: 'error_networkfailure',
         modalOpen: true
      }))
   }
}

function* UpdateLabel(action) {
   try {
      const portal = yield select(getSliceData)
      const selectedPortalId = portal?.payload?.customizer?.selectedportal?.cache[0]?.id
      const {
         labelId,
         content
      } = action?.payload;
      try{
         const response = yield Commonservice.putLabel(labelId, content)
         if (response.status === 200) {
            const payload = { 'status': true, message: { body: "The metafield was successfully updated.", defaultMessage: "Label has been changed successfully", head: 'metadata_edit' } }
            yield put(cutomiser.updateToastDialogModal(payload));
            if (selectedPortalId !== undefined || null) {
               yield put(cutomiser.GetLabelData(selectedPortalId))
            }
         }
      }
      catch(e){
         const error = errorCode(e?.response?.status)
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "Label update error", body:error, defaultMessage: "Unable to update label." } }))
      }
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Label update error", body:error, defaultMessage: "Unable to update label." } }))
   }
}

function* updatePortalPermission(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const portal = yield select(getSliceData)
      const selectedPortalId = portal?.payload?.customizer?.selectedportal
      const { permissionId, content } = action.payload
      try{
            const response = yield Commonservice.changeWorkspacePermission(permissionId, content)
            if(response){
               yield put(cutomiser.updateCommonLoader(false))
               const payload = { 'status': true, message: { body: "permission_has_been_changed", defaultMessage: "Permission has been changed successfully", head: 'permission_changed' } }
               yield put(cutomiser.updateToastDialogModal(payload));
               if (selectedPortalId !== null || undefined) {
                  yield put(cutomiser.UpdatePortalId(selectedPortalId?.cache[0]?.id))
               }
            }
      }
      catch(e){
         yield put(cutomiser.updateCommonLoader(false))
         const error = errorCode(e?.response?.status)
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "Operation failed.", body:error, defaultMessage: "Operation failed.." } }))

      }
      yield put(cutomiser.updateModalChangeStatus(false))
   }
   catch (e) {
      yield put(cutomiser.updateCommonLoader(false))
      yield put(cutomiser.UpdateError({
         status: true,
         message: 'error_networkfailure',
         modalOpen: true
      }))
   }
}

function* DeleteLabel(action) {
   try {
      const portal = yield select(getSliceData)
      const selectedPortalId = portal?.payload?.customizer?.selectedportal?.cache[0]?.id
      const {
         labelId,
         content
      } = action?.payload;
      try{
         yield Commonservice.deleteLabel(labelId, content)
         const payload = { 'status': true, message: { body: "metadata_delete_successfully", defaultMessage: "The Label was successfully deleted.", head: 'metadata_delete' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         if (selectedPortalId !== undefined || null) {
            yield put(cutomiser.GetLabelData(selectedPortalId))
            yield put(cutomiser.UpdateconfirmDialogStatus({ 'status': false }))
         }
      }
      catch(e){
         const error = errorCode(e.response.status)
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "Delete_label_failed", body:error, defaultMessage: "Deleting label failed" } }));
      }
   }
   catch (e) {
      yield put(cutomiser.UpdateError({
         status: true,
         message: 'error_networkfailure',
         modalOpen: true
      }))
   }
}


function* postRemoveGroup(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const { workspaceid } = action.payload
      const response = yield Commonservice.deleteGroup(action.payload)
      if (response) {
         const modalPayload = { 'status': true, message: { body: "group removed successfully", defaultMessage: "Group removed successfully", head: 'removeUser' } }
         yield put(cutomiser.updateToastDialogModal(modalPayload));
         yield put(cutomiser.updateCommonLoader(false))
         yield put(cutomiser.fetchGroupData({ workspaceId: workspaceid.Id }))
      }
   }
   catch (e) {
      yield put(cutomiser.updateCommonLoader(false))
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.UpdateconfirmDialogStatus({'status':false}));
      yield put(cutomiser.updateDriveError({
         status: true, message: {
            head: "Delete group failed", body: error, defaultMessage: error
         }
      }));
   }
}

function* postGroup(action) {
   try {
      try{
         const response = yield Commonservice.sentAddGroup(action.payload)
         const payload = { workspaceId: action.payload.Id }
         const modalPayload = { 'status': true, message: { body: "group added successfully", defaultMessage: "Group created successfully", head: 'add group' } }
         yield put(cutomiser.updateToastDialogModal(modalPayload));
         yield put(cutomiser.fetchGroupData(payload))
      }
      catch(e){
             const errorStatus = errorCode(e.response.status)
             yield put(cutomiser.updateDriveError({
               status: true, message: {
                  head: "group_creation_failed", body: errorStatus, defaultMessage: errorStatus
               }
            }));
      }
   } catch (e) {
      const errorStatus = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({
        status: true, message: {
           head: "group_creation_failed", body: errorStatus, defaultMessage: errorStatus
        }
     }));
   }
}

function* sentGetGroupMembers(action) {
   try {
      yield put(cutomiser.setGrpLoader(true))
      const response = yield Commonservice.fetchGroupMembers(action.payload);
      if(response){
         yield put(cutomiser.storeGroupMembers(response))
         yield put(cutomiser.setGrpLoader(false))
      }
   }
   catch (e) {
      yield put(cutomiser.setGrpLoader(false))
      yield put(cutomiser.updateErrorOnTables({ status: true, statusCode: e?.response?.status }))
   }
}

function* fetchGetWorkSpaceMember(action) {
   try {
      const response = yield Commonservice.getWorkspaceMembers(action.payload)
      if (response) {
         yield put(cutomiser.storeWorkSpacemembers(response))
      }
   }
   catch (e) {
      yield put(cutomiser.UpdateLoading(false))
   }
}
function* postDeleteGroupMember(action) {
   try {
      yield put(cutomiser.UpdateLoading(true))
      const portal = yield select(getSliceData)
      const grpid = portal?.payload?.customizer?.groupMembers
      const response = yield Commonservice.postDeleteGroupMember(action.payload)
      yield put(cutomiser.UpdateLoading(false))
      if (response) {
         yield put(cutomiser.getGroupMembers({ id: grpid.id, limit: 1000, start: 0, page: 1 }))
         yield put(cutomiser.setGrpLoader(false))
      }
   } catch (e) {
      const errorStatus = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({
        status: true, message: {
           head: "edit_group_members_failed", body: errorStatus, defaultMessage: errorStatus
        }
     }));
      yield put(cutomiser.UpdateLoading(false))
      yield put(cutomiser.setGrpLoader(false))
   }
}
function* postGrpMember(action) {
   try {
      const portal = yield select(getSliceData)
      const grpid = portal?.payload?.customizer?.groupMembers
      const response = yield Commonservice.sentAddGrpMember({ group: { id: grpid.id }, user: action.payload })
      if (response) {
         yield put(cutomiser.getGroupMembers({ id: grpid.id, limit: 1000, start: 0, page: 1 }))
         yield put(cutomiser.setGrpLoader(false))
      }
   } catch (e) {
      const errorStatus = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({
        status: true, message: {
           head: "edit_group_members_failed", body: errorStatus, defaultMessage: errorStatus
        }
     }));
      yield put(cutomiser.setGrpLoader(false))
      yield put(cutomiser.UpdateError({ status: true, statusCode: e?.response?.status }))
   }

}



function* updateAllowedClientMultipleUsers(action) {
   const { portalId, content } = action.payload
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const response = yield Commonservice.putAllowedClientsMultipleUsers(portalId, content)
      if (response) {
         yield put(cutomiser.updateCommonLoader(false))
         const payload = { 'status': true, message: { body: "allowed_clients_updated_successfully", defaultMessage: "Allowed Clients updated successfully.", head: 'Allowed Clients' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         if (portalId !== undefined || null) {
            yield put(cutomiser.UpdatePortalId(portalId))
         }
      }
      yield put(cutomiser.updateModalChangeStatus(false))
   }
   catch (e) {
      yield put(cutomiser.updateModalChangeStatus(false))
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "allowed_clients_updation_failed", body:error, defaultMessage: "Allowed clients updation failed." } }))
      yield put(cutomiser.UpdatePortalId(portalId))

   }
}

function* dndFilePutService(action) {
   try {
      yield put(cutomiser.updateDriveLoading(true));
      const { payload } = action;
      const slice = yield select(getSliceData)
      const fileStore = slice?.payload?.customizer?.driveItems;
      const responsePut = yield Commonservice.updateDragnDropFile(payload, fileStore);
      if (responsePut?.id) {
         const fetchPayload = { menuId: payload.id, types: "file,folder", key: payload.key };
         yield put(cutomiser.fetchDriveItemsRequest(fetchPayload));
         yield put(cutomiser.updateDriveLoading(false));
      }
   } catch (e) {
      yield put(cutomiser.updateDriveLoading(false));
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Upload Error", body: "Upload failed, please try again" } }));

      console.log('dndFilePutService error', e);
   }
}

function* fetchDriveItemsRequest(action) {
   const { menuId, types, key, loader } = action.payload;

   try {
      if(loader){
         yield put(cutomiser.updateDriveLoader(true))
      }
      let responsePut;
      if (key === "workspace") {
         responsePut = yield Commonservice.getWorkspaceRootFolderChildren(menuId, types);
      } else if (key === "folder") {
         responsePut = yield Commonservice.getFolderAndFilesChildrenEndpoint(menuId);
      } else if (key === "smart_folder") {
         responsePut = yield Commonservice.getSmartFolderChildrenEndpoint(menuId);
      }
      if (responsePut?.entries) {
         yield put(cutomiser.updateDriveItems(responsePut));
      }
      if(loader){
         yield put(cutomiser.updateDriveLoader(false))
      }
   } catch (e) {
      if(e?.response?.status===403 || e?.response?.status===404){
         yield put(cutomiser.addDriveSidebarCache([]))
         yield put(cutomiser.updateBreadcrubDrive(''))
      }else{
         const errorStatus = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({
        status: true, message: {
           head: "Operation failed.", body: errorStatus, defaultMessage: "Network error please try again"
        }
     }));
      }
            if(loader){
         yield put(cutomiser.updateDriveLoader(false))
      }
      console.log('profileupdate photo', e);
   }
}


function* fetchSearchFile(action) {
   try {
      yield put(cutomiser.UpdateLoading(true))
      const response = yield Commonservice.getSearchFile(action.payload)
      if (response) {
         yield put(cutomiser.setSavedFiles(response))
      }
      yield put(cutomiser.UpdateDriveSelectedItem([]))
      yield put(cutomiser.UpdateLoading(false))
   }
   catch (e) {
      yield put(cutomiser.UpdateLoading(false))
      yield put(cutomiser.UpdateError({ status: true ,statusCode: e?.response?.status}))
   }
}

function* sentRenameGroup(action) {
   try {
      const { Id, fields, id } = action.payload
      const response = yield Commonservice.renameGroup(fields, id)
      if (response) {
         const payload = { 'status': true, message: { body: "Rename_success_body", defaultMessage: "The group has been renamed.", head: 'Group_Rename' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         yield put(cutomiser.fetchGroupData({ workspaceId: Id }))
      }
   } catch (e) {
      const errorStatus = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({
        status: true, message: {
           head: "group_creation_failed", body: errorStatus, defaultMessage: errorStatus
        }
     }));
   }
}


function* restoreItemFromRecycleBinFunction(action) {
   const { driveSelectedItem , menuId} = action.payload
   const response = { success : [] , failure : []}
   const selected = yield select(getSliceData)
   const resotrationFailedItems = selected?.payload?.customizer?.resotrationFailedItems
   let respFeedbackFailure
   let errorCatched = false
            for(let i=0 ;i < driveSelectedItem?.length;i++){
                const content = {
                      name: driveSelectedItem[i]?.original?.name,
                      id : driveSelectedItem[i]?.original?.id,
                      parent: {
                          id: driveSelectedItem[i]?.original?.parent?.id === null ? 0 : driveSelectedItem[i]?.original?.parent?.id
                      }
                  }
                  try{
                     const id = driveSelectedItem[i]?.original?.id
                     const type = driveSelectedItem[i]?.original?.type
                     yield Commonservice.restoreFromRecyclebin(id, content,type)
                     response.success = [...response.success,{successItem : content,
                        id : driveSelectedItem[i]?.original?.id,type : driveSelectedItem[i]?.original?.type}]
                      yield put(cutomiser.FetchRecyclbinChildren(menuId));

                  }
                  catch(e){
                     errorCatched = true
                     if(e.response.status === 409){
                        const findItem =  resotrationFailedItems?.some((faldItem) => faldItem?.failedItem?.id === driveSelectedItem[i]?.original?.id) 
                        if(!findItem || resotrationFailedItems?.length === 0){
                    
                           response.failure = [...response.failure,   {
                              failedItem : content,
                              code : e.response.status,
                              type : driveSelectedItem[i]?.original?.type,
                              id : driveSelectedItem[i]?.original?.id
                           }]
                           yield put(cutomiser.updateresotrationFailedItems(response.failure))
                           yield put(cutomiser.updaterestoreItemsinWorkspaceModal(true))
                        }

                           }
                           else if(e.response.status === 403){
                              const findItem =  resotrationFailedItems?.some((faldItem) => faldItem?.failedItem?.id === driveSelectedItem[i]?.original?.id) 
                              if(!findItem || resotrationFailedItems?.length === 0){
                          
                                 response.failure = [...response.failure,   {
                                    failedItem : content,
                                    code : e.response.status,
                                    type : driveSelectedItem[i]?.original?.type,
                                    id : driveSelectedItem[i]?.original?.id
                                 }]
                              }
                                 yield put(cutomiser.updateresotrationFailedItems(response.failure))
                                 yield put(cutomiser.updaterestoreItemsinWorkspaceModal(true))
                              }
                           
                            

                              yield put(cutomiser.updaterestoreFeedbackRecycleBinFailureState(respFeedbackFailure))
                           }
            }

            if(!errorCatched){
               yield put(cutomiser.updaterestoreItemsinWorkspaceModal(false))
            }
         yield put(cutomiser.updateNewDrive({workspaceId:menuId}))
            yield put(cutomiser.updateCommonLoader(false))
   }
   

   function* retryRestoreItemFromRecycleBinFunction(action) {
      const { id, content, menuId,type } = action.payload
      const response = { 
          failure : []}
      const selected = yield select(getSliceData)
      const resotrationFailedItems = selected?.payload?.customizer?.resotrationFailedItems
      const tempArrayFailedItems = [...resotrationFailedItems]
         try{
            yield put(cutomiser.updateCommonLoader(true))
            yield Commonservice.restoreFromRecyclebin(id, content,type)
            response.success.push({
               successItem : content,
               id 
            })
            const tempRestoreId = yield tempArrayFailedItems?.findIndex((item) => item?.failedItem?.id  === id)
             yield tempArrayFailedItems?.splice(tempRestoreId,1)
            yield put(cutomiser.updateresotrationFailedItems(tempArrayFailedItems))
            yield put(cutomiser.FetchRecyclbinChildren(menuId));
            yield put(cutomiser.FetchWorkspacesRecursive(selected?.payload?.customizer?.selectedportal?.cache[0]?.id))
            yield put(cutomiser.updateCommonLoader(false))
         }
         catch(e){
             if(e.response.status === 409){
               yield put(cutomiser.updaterestoreItemsinWorkspaceModal(true))
               response.failure = [...response.failure ,    {
                  failedItem : content,
                  code : e.response.status,
                  id ,
                  type
               }]
               yield put(cutomiser.updateresotrationFailedItems(response.failure))
            yield put(cutomiser.updateCommonLoader(false))
            }
            else if(e.response.status === 403){
               yield put(cutomiser.updateDriveError({
                  status: true, message: {
                     head: "Restore", body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action."
                  }
               }));
            yield put(cutomiser.updateCommonLoader(false))
            }
         }
      }
   
      function* retryMultipleRestoreItemFromRecycleBinFunction(action) {
         const { content,menuId,restorationWorkspace,parent } = action.payload
         const response = { success : [] , failure : []}
         const selected = yield select(getSliceData)
         const resotrationFailedItems = selected?.payload?.customizer?.resotrationFailedItems
         const driveData = selected?.payload?.customizer?.driveData
         const countSuccess = {
            flag : false,
            count: 0
         }
            try{

            yield put(cutomiser.updateCommonLoader(true))

               for(let i =0 ; i < content?.length ; i++){
                  const cntnt = {
                     name : content[i]?.failedItem?.name,
                     parent : {
                        id : restorationWorkspace?.parentId
                     }
                  }
                  try{
                     yield Commonservice.restoreFromRecyclebin(content[i]?.failedItem?.id,cntnt,content[i]?.type)
                     response.success = [...response.success,content[i]]
                     const responseArray = removeObjectFromArray(resotrationFailedItems,content[i])
                     yield put(cutomiser.updateresotrationFailedItems(responseArray))
                     yield put(cutomiser.updateSuccessInRetryRestore(response.success))
                     yield put(cutomiser.updateCommonLoader(false))
                     yield put(cutomiser.FetchRecyclbinChildren(menuId));
                     countSuccess.count+=1
                     // Updating open node data
                     const folderId = restorationWorkspace?.parentId
                     if(menuId && folderId){
                        const folderchild  = yield Commonservice.getFolderChildrenEndpoint(folderId)
                        const arr = folderchild.entries.map((itemRes)=>{return  {
                           id: itemRes.id,
                           entity_type:"folder",
                           workspaceId : menuId,
                           name: itemRes.name,
                           Nodes: []
                        }})
                        const treeData = 
                           {
                           id: 4,
                           type: 'folder',
                           key: '',
                           name: 'Workspaces',
                           data: 'Files/Workspaces',
                           pivot: '4',
                           Nodes: [...driveData],
                           url: 'user/drive/workspace',
                           root: true
                           }
                        
                  const zaz = makeObjectsTraversing(treeData,[...arr],folderId)
                  yield put(cutomiser.updateDriveData([...zaz]))
                     }
               }
                  catch(e){
                      yield put(cutomiser.updateCommonLoader(false))
                      countSuccess.flag = true
                     if(e.response.status === 409){
                        yield put(cutomiser.updaterestoreItemsinWorkspaceModal(true))
                        if(resotrationFailedItems?.length === 0 && resotrationFailedItems?.find((faldItem) => faldItem?.failedItem?.id === content[i]?.failedItem?.id) === null || {}){
                           response.failure = [...response.failure , content[i]]
                           yield put(cutomiser.updateresotrationFailedItems(response?.failure))
                           yield put(cutomiser.updateErrorInRetryRestore(response.failure))
                        }
                     }
                     else if(e.response.status === 403){
                        yield put(cutomiser.updateDriveError({
                           status: true, message: {
                              head: "Restore", body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action."
                           }
                        }));
                     }
                  }
               }
               if(countSuccess.count === resotrationFailedItems?.length && !countSuccess.flag){
                   yield put(cutomiser.updaterestoreItemsinWorkspaceModal(false));
               }

            }
            catch(e){
             console.log("E",e)
            }
         }

function* clearRecycleBinFunction(action) {
   yield put(cutomiser.updateCommonLoader(true))
   try {
      const { workspaceId, portalId } = action.payload;
      const response = yield Commonservice.clearRecyclebin(portalId);
      if (response.status === 204) {
         yield put(cutomiser.updateCommonLoader(false))
         const payload = { 'status': true, message: { body: "empty_recyclebin", defaultMessage: "Recyclebin Cleared", head: 'recyclebin_head' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         yield put(cutomiser.FetchRecyclbinChildren(workspaceId))
      }
   }
   catch (e) {
      yield put(cutomiser.updateCommonLoader(false))
      yield put(cutomiser.UpdateError({ status: true, statusCode: e?.response?.status }))
   }
}

function* fetchSmartFolderFunction(action) {

   try {
      yield put(cutomiser.updateDriveLoading(true));
      const { id, type } = action.payload;
      let response = [];
      if (type === "workspace") {
         response = yield Commonservice.getWorkspaceRootSmartFolderChildren(id)
      } else if(type ==="smart_folder"){
         response = yield Commonservice.getSmartFolderChildrenEndpoint(id)
      }

      else if(type === "folder"){
         response = yield Commonservice.getFolderAndFilesChildrenEndpoint(id)
      }
      yield put(cutomiser.updateFetchSmartFolder(response));
      yield put(cutomiser.updateDriveLoading(false));
   }
   catch (e) {
      yield put(cutomiser.updateFetchSmartFolder([]));
      yield put(cutomiser.updateDriveLoading(false));
      console.log("Error!!", e)
   }
}

function* createSmartFolderFunction(action) {
   try {
      const { workspaceId, contentName, contentLabel, filesAndFolders } = action.payload;

      try {
         const response = yield Commonservice.createSmartFolderName(workspaceId, contentName)
         if (response.status === 201) {
            const fileId = response?.data?.id
            try {

               for (let im = 0; im < contentLabel.length; im++) {
                  const responseTwo = yield Commonservice.createSmartFolderMetaData(fileId, contentLabel[im])
                  if (responseTwo?.status === 201) {
                     const payload = { 'status': true, message: { body: "Create_smart_folder_success", defaultMessage: "Successfully created Smart Folder", head: 'smartfolder_created' } }
                     yield put(cutomiser.updateToastDialogModal(payload));
                     yield put(cutomiser.updateFetchSmartFolderTrigger({
                        type: 'workspace',
                        id: workspaceId,
                     }))
                     if (filesAndFolders?.length > 0) {
                        for (let i = 0; i <= filesAndFolders.length; i++) {
                           yield put(cutomiser.smartFolderMetaFieldTriggerForFilterCrieteriaTrigger({filesAndFolders : filesAndFolders[i]?.id}))
                        }
                     }
                  }
                  }
                  yield put(cutomiser.updateGetNewSmartFolder({
                     workspaceId
                  }))
            }
            catch (e) {
               const error = errorCode(e?.response?.status)
                  yield put(cutomiser.updateDriveError({
                     status: true, message: {
                        head: "Operation failed.", body: error, defaultMessage: error
                     }
                  }));
            }
         }
      }
      catch (e) {
         if (e.response.status === 403) {
            yield put(cutomiser.updateDriveError({
               status: true, message: {
                  head: "Operation failed.", body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action."
               }
            }));
         }
         else {
            const payload = { 'status': true, message: { body: "Create_smart_folder_failed", defaultMessage: "Failed to create Smart Folder", head: 'Operation failed' } }
            yield put(cutomiser.updateToastDialogModal(payload));
         }
      }
   }
   catch (e) {

      const payload = { 'status': true, message: { body: "Create_smart_folder_failed", defaultMessage: "Failed to create Smart Folder", head: 'Operation failed' } }
      yield put(cutomiser.updateToastDialogModal(payload));
   }
}

function* smartFolderMetaFieldTriggerFunction(action) {
   try {
      const fileId = action.payload
      const response = yield Commonservice.getSmartFolderMetaData(fileId)
      if (response.status === 200) {
         const Data = response?.data
         const final_data = {
            fileId: Data[0]?.smart_folder?.id,
            name: Data[0]?.smart_folder?.name,
            values: Data.map((item) => {
               return {
                  id: item.id,
                  label: item?.meta_field?.name,
                  operator: item?.condition_type,
                  value: item?.value,
                  type: item?.meta_field?.type
               }
            })
         }
         yield put(cutomiser.populateSmartFolderMetaFieldData(final_data))
      }
   }
   catch (e) {
      yield put(cutomiser.populateSmartFolderMetaFieldData({}))
   }
}
function* sentDeleteRecycleBinRow(action) {
   yield put(cutomiser.updateCommonLoader(true))
      const {  driveSelectedItem, menuId } = action.payload
      let fileType 
      const failed=[]
      for(let i=0; i<driveSelectedItem.length; i++){
         try{
                if (driveSelectedItem[i]?.original?.type === "smart_folder") {
                    fileType = "smart-folders"
                }else if(driveSelectedItem[i]?.original?.type === "folder") {
                              fileType = "folders"
               }else {
                              fileType = "files"
               }
               const response = yield Commonservice.deleteRecycleBinData(fileType, driveSelectedItem[i]?.original?.id)
         }catch(e){
            const status = errorCode(e.response.status)
                  failed.push({statusCode:status,name:driveSelectedItem[i].original.name});
         }
      }
      yield put(cutomiser.updateCommonLoader(false))
      yield put(cutomiser.updateRecyclebinItemFlag(false))
      if(failed.length>0){
         yield put(cutomiser.updateDeleteErrorModal({state:true,data:[...failed], modalHeader:"delete_op_failed"}))
      }else{
               const payload = { 'status': true, message: { body: "delete_perm", defaultMessage: "Selected files/folders deleted permanently", head: 'delete-success' } }
            yield put(cutomiser.updateToastDialogModal(payload));
            const response = yield Commonservice.getWorkspaceTrashChildrenEndpoint(menuId)
            yield put(cutomiser.PutRecyclbinChildren(response))
      }
      yield put(cutomiser.UpdateDriveSelectedItem([]))
}

function* sentDeleteWorkSpaceItems(action) {
   yield put(cutomiser.updateCommonLoader(true))
   try {
      const response = yield Commonservice.deleteRecycleBinItems(action.payload)
      if (response.status === 204) {
         yield put(cutomiser.FetchRecyclbinChildren(action.payload))
         yield put(cutomiser.updateCommonLoader(false))
         const payload = { 'status': true, message: { body: "workspace_item_cleared", defaultMessage: "Successfully cleared items in workspace", head: 'delete-success' } }
         yield put(cutomiser.updateToastDialogModal(payload));
      }
   } catch (e) {
      yield put(cutomiser.updateCommonLoader(false))
      yield put(cutomiser.UpdateError({ status: true, statusCode: e?.response?.status }))
   }
}

function* configureSMartFolderFunction(action) {
   try {
      const Final_Data = []
      const { SmarfFolderId, content, refetch, filesAndFolders } = action.payload
      const {id,type} = action?.payload
      const { content1, content2 } = content
      const namecontent = { name: content1?.name?.name }
      yield put(cutomiser.updateDriveLoading(true));
      try {
         yield Commonservice.configureSmartFolder(namecontent, SmarfFolderId, content2)
         yield put(cutomiser.updateDriveLoading(false));
         yield put(cutomiser.updateLabelEditStatus({
            dispatch: false,
            disabled: false
         }))
         if (filesAndFolders?.length > 0) {
            for (let i = 0; i < filesAndFolders.length; i++) {
               const response = yield Commonservice.getSmartFolderMetaData(filesAndFolders[i]?.id)
               Final_Data.push(...response?.data)
            }
            yield put(cutomiser.updatesmartFolderMetaFieldTriggerForFilterCrieteria(Final_Data))
            yield put(cutomiser.updateFetchSmartFolderTrigger({
               type, id
            }))
         }
      }
      catch (e) {
         if (e.response.status === 403) {
            yield put(cutomiser.updateDriveError({
               status: true, message: {
                  head: "Operation failed.", body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action."
               }
            }));
         }
      }
   }
   catch (e) {
      const payload = { 'status': true, message: { body: "Create_smart_folder_failed", defaultMessage: "Failed to create Smart Folder", head: 'Operation failed' } }
      yield put(cutomiser.updateToastDialogModal(payload));
   }
}

function* deleteSmartFolderFunction(action) {
   try {
      const Final_Data = []
      const { fileId, workspaceId, filesAndFolders } = action.payload;
      const selected = yield select(getSliceData)
      const selectedPortal = selected?.payload?.customizer?.selectedportal?.cache[0]?.id
      yield Commonservice.deleteSmartFolderItem(fileId);
      yield put(cutomiser.FetchWorkspacesRecursive(selectedPortal))

      yield put(cutomiser.UpdateconfirmDialogStatus({ 'status': false }))
      const payload = { 'status': true, message: { body: "smart_folder_deleted", defaultMessage: "Smart folder deleted successfully", head: 'Smart folder was deleted' } }
      yield put(cutomiser.updateToastDialogModal(payload));
      if (workspaceId !== undefined || null) {
         yield put(cutomiser.updateFetchSmartFolderTrigger({
            type: 'workspace',
            id: workspaceId,
         }))
      }
      if (filesAndFolders?.length > 0) {
         for (let i = 0; i < filesAndFolders.length; i++) {
            const response = yield Commonservice.getSmartFolderMetaData(filesAndFolders[i]?.id)
            Final_Data.push(...response?.data)
         }
         yield put(cutomiser.updatesmartFolderMetaFieldTriggerForFilterCrieteria(Final_Data))
      }
   }
   catch (e) {
      yield put(cutomiser.UpdateError({ status: true }))
   }
}

function* postnewFolder(action) {
   try {
      const { workspaceId, key, parent } = action.payload
      const finalResponse = { failed: false }
      const selected = yield select(getSliceData)
      let menuId = ""
      const folderId = parent.id
      if (key === "folder") {
         menuId = folderId
      }
      if (key === "workspace") {
         menuId = workspaceId
      }
      const fetchPayload = { menuId, types: "file,folder", key }
      try {
         const response = yield Commonservice.createNewFileFolder(action.payload);
         if (response) {
            // yield put(cutomiser.FetchWorkspacesRecursive(selected?.payload?.customizer?.selectedportal?.cache[0]?.id))
            yield put(cutomiser.updateNewDrive({workspaceId,folderId:parent?.id}))
            yield put(cutomiser.fetchDriveItemsRequest(fetchPayload))
         }
      } catch (e) {
         const error = errorCode(e?.response?.status)
         finalResponse.failed = true
      }
      if (finalResponse.failed) {
         yield put(cutomiser.updateDriveError({
            status: true, message: {
               head: "Operation failed.", body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action."
            }
         }))
      }
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      console.log("error", error);
      yield put(cutomiser.UpdateError({ status: true, }))
   }
}

function* createNewmetaDataSmartFolder(action) {
   const { content, fileId, refetch, filesAndFolders } = action?.payload
   const { id, type } = refetch
   try {
      const Final_Data = []

      const resp = yield Commonservice?.createSmartFolderMetaData(fileId, content)
      yield put(cutomiser.updateFetchSmartFolderTrigger({
         type, id
      }))
      if (filesAndFolders?.length > 0) {
         for (let i = 0; i < filesAndFolders.length; i++) {
            const response = yield Commonservice.getSmartFolderMetaData(filesAndFolders[i]?.id)
            Final_Data.push(...response?.data)
         }
         yield put(cutomiser.updatesmartFolderMetaFieldTriggerForFilterCrieteria(Final_Data))
      }
      yield put(cutomiser.updateFetchSmartFolderTrigger({ type, id }))
   }
   catch (e) {
      console.log("E", e)
   }
}

function* sentGetEmail(action) {
   try {
      const response = yield Commonservice.sentEmail(action.payload)
      if (response) {
         yield put(cutomiser.updateStoreEmail(response))
      }
   } catch (e) {
      yield put(cutomiser.updateStoreEmail(''))
      const error = errorCode(e?.response?.status)
      console.log("error", error);
   }
}

function* updatelinkAcces(action) {
   try {
      const { fileId, data, menuId, key } = action.payload;
      const fetchPayload = { menuId, types: "file,folder", key };
     
         const response = yield Commonservice.updateLinkAccess(fileId, data);
         if (response) {
            yield put(cutomiser.fetchDriveItemsRequest(fetchPayload));
         }
   } catch (e) {
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Operation failed.", body:errorBody, defaultMessage: "Error , Please try again" } }));
   }
}

function* deleteMetaSmartFolderFunction(action) {
   try {
      const Final_Data = []
      const { fileId, filesAndFolders } = action.payload;
      const content = {
         id: fileId
      }
      yield put(cutomiser.updateDriveLoading(true));
      yield Commonservice.deleteMetafromSmartFolder(fileId, content)
      yield put(cutomiser.updateDriveLoading(false));
      if (filesAndFolders?.length > 0) {
         for (let i = 0; i < filesAndFolders.length; i++) {
            const response = yield Commonservice.getSmartFolderMetaData(filesAndFolders[i]?.id)
            Final_Data.push(...response?.data)
         }
         yield put(cutomiser.updatesmartFolderMetaFieldTriggerForFilterCrieteria(Final_Data))
      }
   }
   catch (e) {
      console.log("error", e);
   }
}

function* getFilePreview(action) {
   try {
      const response = yield Commonservice.sentFilePreview(action.payload)
      if (response) {
         const source = {
            type: "url",
            url: response
         }
         yield put(cutomiser.updateFilePeviewLink({ source }))
      }
   } catch (e) {
      console.log(e)
   }
}

function* getViewId(action) {
   try {
      const response = yield Commonservice.sentViewId(action.payload)
      if (response) {
         yield put(cutomiser.updateViewId(response?.data?.viewingSessionId))
      }
   } catch (e) {
      console.log(e)
   }
}
function* getFolderDownload(action) {
   try {
      const response = yield Commonservice.sentFolderDownload(action.payload)
      if (response) {
         yield put(cutomiser.updateFolderLink(response))
      }
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "download_error", body: error, defaultMessage:"Error ,Please try again" } }));
      console.log("error", error);
   }
}

function* fetchVersions(action) {
   const selected = yield select(getSliceData)
   const previewStatus = selected?.payload?.customizer?.previewStatus
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const response = yield Commonservice.sentGetVersions(action.payload)
      if (response) {
         yield put(cutomiser.updateCommonLoader(false))
         yield put(cutomiser.updateStoreVersions(response?.data?.entries))
      }
   } catch (e) {
      yield put(cutomiser.updateCommonLoader(false))
      if(previewStatus?.status){
         yield put(cutomiser.updatePreviewStatus({status:false}))
      }
      const error = errorCode(e?.response?.status)
      console.log("error", error);
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Operation failed.", body: error, defaultMessage:"Network Error" } }));
   }
}

function* addFileComment(action) {
   try {
      const portal = yield select(getSliceData)
      const getVersion = portal?.payload?.customizer?.getVersions
      const finalResponse = { failed: false }
      const { menuId, key } = action.payload

      try {
         const response = yield Commonservice.postFileComment(action.payload)
         if (response) {
            yield put(cutomiser.updateGetVersions(getVersion))
            const payload = { 'status': true, message: { body: "comment_added_body", defaultMessage: "Comment added successfully", head: 'comment_added_head' } }
            yield put(cutomiser.updateToastDialogModal(payload));
            if (menuId && key) {
               if (key === "workspace") {
                  const pl = { key: "workspace", menuId, types: "file,folder" };
                  yield put(cutomiser.fetchDriveItemsRequest(pl));
               } else if (key === "folder") {
                  const pl = { key: "folder", menuId, types: "file,folder" };
                  yield put(cutomiser.fetchDriveItemsRequest(pl));
               }
            }
         }
      } catch (e) {
         finalResponse.failed = true
      }
      if (finalResponse?.failed) {
         yield put(cutomiser.updateDriveError({
            status: true, message: {
               head: "Operation failed.", body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action."
            }
         }));
      }
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      console.log("error", error);
      yield put(cutomiser.UpdateError({ status: true }))
   }
}

function* drivePasteOperations(action) {
   try {
      const cutData = {same:[],diff:[]}
      const menuDetails = action.payload;
      if (menuDetails) {
         const slice = yield select(getSliceData)
         const fileStore = slice?.payload?.customizer?.driveItems;
         const editOperation = slice?.payload?.customizer?.driveEditOperations;
         const driveItems = slice?.payload?.customizer?.driveItems;
         const pasteControllerState = slice?.payload?.customizer?.pasteControllerState
         editOperation?.driveSelectedItem?.forEach(i => 
            driveItems?.entries?.forEach(l =>
               { 
                  if(l.name === i.original.name)
                     {
                        cutData.same=[...cutData.same,i]
                     }
               }))

               cutData.diff = editOperation?.driveSelectedItem?.filter(
                  i=>cutData?.same?.every(
                     l=>{return i.original.name!==l.original.name}))

         const breadcrubDrive = slice?.payload?.customizer?.breadcrubDrive;
         const path = breadcrubDrive?.split("/")?.map(i => i && i.split("<<")[1]).filter(i=>i!=="");
         const pathData = editOperation?.driveSelectedItem?.filter(i=>!path.includes(String(i.original.id)))
         let data = pathData

         if(editOperation.operation==="cut" && pasteControllerState.operationKey==="cancel"){
            data = cutData.diff
         }else if(editOperation.operation==="copy" && pasteControllerState.operationKey==="cancel"){
            data = cutData.diff
         }
         const currentWorkspace = breadcrubDrive && breadcrubDrive?.split('/')[2].split('<<')[1];
         const pasteValidation = data.length > 0 && data[0]?.original?.workspace?.id === parseInt(currentWorkspace, 10);
         const diffWrkspace = data.length > 0 && data[0]?.original?.workspace?.id !== parseInt(currentWorkspace, 10);
         const sameCutOperation = path.includes(String(editOperation.driveSelectedItem[0].original.id))
         const sameFlag = editOperation.driveSelectedItem.length>0 && editOperation.driveSelectedItem.find(i=>path.includes(String(i.original.id)))
      
         if (pasteControllerState?.operationState || pasteValidation && !cutData?.same?.length>0) { // validate on same workspace copy or cut
            yield put(cutomiser.updateDriveLoading(true));
            const response = { success: [], failure: [] };

            for (let i = 0; i < data.length; i++) {
               let singleResp;
               try {
                  singleResp = yield Commonservice.drivePasteOperation(data[i].original, fileStore, editOperation, menuDetails);
                  response.success.push(data[i]);

               } catch (e) {
                  const status = errorCode(e.response.status)
                  response.failure.push({statusCode:status,name:data[i].original.name});
               }
            };

            if (response) {
               const selectedportal = slice?.payload?.customizer?.selectedportal;
               const fetchPayload = { menuId: menuDetails.menuId, types: "file,folder", key: menuDetails.key };
               /* Update leftside drive for updated items */
               const currentworkspaceDetails = findWorkspaceDetails(breadcrubDrive,menuDetails.key )
               yield put(cutomiser.updateNewDrive({workspaceId: currentworkspaceDetails.workspaceId,folderId:currentworkspaceDetails.parent.id}))
               yield put(cutomiser.fetchDriveItemsRequest(fetchPayload));
               if (response.failure.length > 0) {
                  yield put(cutomiser.updateDeleteErrorModal({state:true,data:[...response.failure],modalHeader:"Edit Operation Failed"}))
               }
               yield put(cutomiser.updateDriveLoading(false));

               if(pasteControllerState?.operationState){
                  yield put(cutomiser.updatePasteController({...pasteControllerState,operationState:false}))
               }

            if(editOperation.driveSelectedItem.length===1 && editOperation.operation==="cut"){
               yield put(cutomiser.updateDriveEditOperations(undefined))
            }

            if(sameFlag?.original?.id){
               yield put(cutomiser.updateDriveError({ status: true, message: { head: "Edit Operation Failed", body: "edit_cut_paste_same", defaultMessage: "A folder cannot be moved in under itself. Please choose a different location." }}))
            }

            }
         }else if(cutData?.same?.length>0){
            yield put(cutomiser.updatePasteController({state:true, operationState:false,  operation:editOperation.operation, key:editOperation.key}))
         }else if(diffWrkspace) {
            yield put(cutomiser.updateDriveError({ status: true, message: { head: "Edit Operation Failed", body: "Edit operation on different workspace not allowed", defaultMessage: "Edit operation on different workspace not allowed." } }));
            yield put(cutomiser.updateDriveLoading(false));
         }else if(sameCutOperation){
            yield put(cutomiser.updateDriveError({ status: true, message: { head: "Edit Operation Failed", body: "edit_cut_paste_same", defaultMessage: "Paste operation in same folder not allowed." }}))
         }
      }


   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      console.log("error in cut", error);
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Edit Operation Failed", body: "Please try again", defaultMessage: "Please try again" } }));
      yield put(cutomiser.updateDriveLoading(true));
   }
}

function* getFilePermission(action) {
   const { menuId, fileId, key } = action.payload
   const workspaceId = menuId
   const userid = menuId
   const memberPayload = { id: workspaceId, page: 1, start: 0, limit: 1000 }


   try {

      yield put(cutomiser.updateCommonLoader(true))
      let response
      if (key === 'folder') {
         response = yield Commonservice.getFolderPermissionsEndpoint(fileId)
      } else if (key === "file") {
         response = yield Commonservice.getFilePermissionsEndpoint(fileId)
      }

      const userData = yield Commonservice.getWorkspacePermissionsEndpoint(userid);
      const wrkspcMembers = yield Commonservice.getWorkspaceMembers(memberPayload)
      const AdminGroupData = yield Commonservice.getWorkspaceGroupsEndpoint(workspaceId)
      const highestPermission = yield Commonservice.highestFolderFilePrem(fileId)

      if (response?.data && AdminGroupData?.entries && wrkspcMembers?.data && userData) {
         yield put(cutomiser.updateStoreFolderPermission({
            GroupsData: AdminGroupData?.entries,
            fetchMembersDetails: userData,
            storeFolderPermission: response?.data,
            workSpaceMembers: wrkspcMembers?.data,
            highest: highestPermission?.data
         }))
         yield put(cutomiser.updateCommonLoader(false))

      }

   } catch (e) {
      yield put(cutomiser.updateDriveItemPermission(false))
      yield put(cutomiser.updateCommonLoader(false))
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "permission_error_head", body:errorBody, defaultMessage: "Error , Please try again" } }));
      console.log(e)
   }
}

function* UpdatedriveSendLinkStateService(action) {
   try {
      const { content } = action.payload
      const response = yield Commonservice.postDriveSendLink(content)
      if (response) {
         const payload = { 'status': true, message: { body: "send_link_body", defaultMessage: "Email sent successfully.", head: 'Send link' } }
         yield put(cutomiser.updateToastDialogModal(payload));
      }
   }
   catch (e) {
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Send link", body:errorBody, defaultMessage: "Error , Please try again" } }));
   }
}

function* smartFolderMetaFieldTriggerForFilterCrieteriaTriggerFunction(action) {
   try {
      const Final_Data = []
      const { filesAndFolders } = action.payload
      for (let i = 0; i < filesAndFolders?.length; i++) {
         if (filesAndFolders && filesAndFolders[i]?.id) {
            const response = yield Commonservice.getSmartFolderMetaData(filesAndFolders[i]?.id)
            Final_Data.push(...response?.data)
         }
      }
      yield put(cutomiser.updatesmartFolderMetaFieldTriggerForFilterCrieteria(Final_Data))
   }
   catch (e) {
      console.log("Error!!", e)
   }
}

function* updatepostDriveLabelDataOPeration(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const { content, selectedID, menuId, type, key } = action.payload
         const response = yield Commonservice.postDriveLabelDataUrl(selectedID, content, type)
         const payloads = { key, menuId, types: "file,folder" };
         yield put(cutomiser.fetchDriveItemsRequest(payloads));
         yield put(cutomiser.updateCommonLoader(false))
   }
   catch (e) {
      console.log("Error!", e)
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Operation failed.", body:errorBody, defaultMessage: "Error , Please try again" } }));
      yield put(cutomiser.updateCommonLoader(true))
   }
}

function* updateputDriveLabelStateOPeration(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const { content, metaId, menuId, key } = action.payload
      const response = yield Commonservice.putDriveLabelDataUrl(metaId, content)
      const payloads = { key, menuId, types: "file,folder" };
      yield put(cutomiser.fetchDriveItemsRequest(payloads));
      yield put(cutomiser.updateCommonLoader(false))
   }
   catch (e) {
      console.log("error", e)
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Operation failed.", body:errorBody, defaultMessage: "Error , Please try again" } }));
      yield put(cutomiser.updateCommonLoader(true))
   }
}

function* updatedeleteDriveLabelStateOperation(action) {
   try {
      yield put(cutomiser.updateCommonLoader(true))
      const { content, deleteId, menuId, key } = action?.payload
      const response = yield Commonservice.deleteDriveLabelDataUrl(deleteId, content)
      const payloads = { key, menuId, types: "file,folder" };
      yield put(cutomiser.fetchDriveItemsRequest(payloads));
      yield put(cutomiser.updateCommonLoader(false))
   }
   catch (e) {
      console.log("Error!", e)
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Operation failed.", body:errorBody, defaultMessage: "Error , Please try again" } }));
      yield put(cutomiser.updateCommonLoader(true))
   }
}
function* updatefFleOnOfStatus(action) {
   const { event, postData, driveSelectedItem, menuId, key } = action.payload;
   try {
      const fetchPayload = { menuId, types: "file,folder", key }
      let response = '';
      if (event === 'subscription') {
         response = yield Commonservice.postFileSubscription(postData, driveSelectedItem);
      } else if (event === 'sync') {
         response = yield Commonservice.postFileSync(postData, driveSelectedItem);
      } else if (event === 'lock') {
            response = yield Commonservice.postFileLock(postData, driveSelectedItem);
      } 
      if(event === "subscription" && response){
         const newSelected = createNewSelected(driveSelectedItem.original,"file_email_options",response?.data)
         if(newSelected){
            yield put(cutomiser.UpdateDriveSelectedItem([{original:newSelected}]))
         }
      }
      if (response.status === 200 || response.status === 204) {
         yield put(cutomiser.fetchDriveItemsRequest(fetchPayload));
      }
   }
   catch (e) {
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({
         status: true, message: {
            head: "Edit Operation Failed", body: errorBody, defaultMessage: `The operation could not be completed for the following file:
      Permission denied ${driveSelectedItem?.original?.name}.`
         }
      }));
      console.log("Error!!", e)
   }
}

function* sentUserPermissions(action) {
   try {
      const response = yield Commonservice.putUserPermission(action.payload)
   } catch (e) {
      console.log(e)
   }
}

function* sentUpdateFilePermissions(action) {
   const { postPayload, putPayload, key, id } = action.payload
   const AuthorizationFailed = {
      failed: false
   }
   if (postPayload.length > 0) {

      if (key === "folder") {
         try {
            const response = yield all(postPayload.map((item) => {
               const response1 = Commonservice.postFolderPermission(item, id)
               return response1
            }))
            const payload = { 'status': true, message: { body: "permission_body", defaultMessage: "Permission updated successfully", head: 'permission_head' } }
            yield put(cutomiser.updateToastDialogModal(payload));
         } catch (e) {
            AuthorizationFailed.failed = true
         }
         if (AuthorizationFailed.failed) {
            yield put(cutomiser.updateDriveError({ status: true, message: { head: "permission_error_head", body: "permission_error_body", defaultMessage: "Some permissions are not updated,please try again." } }));
         }

      }


      if (key === "file") {

         try {
            const response = yield all(postPayload.map((item) => {
               const response1 = Commonservice.postFilePermission(item, id)
               return response1
            }))
            const payload = { 'status': true, message: { body: "permission_body", defaultMessage: "Permission updated successfully", head: 'permission_head' } }
            yield put(cutomiser.updateToastDialogModal(payload));
         } catch (e) {
            AuthorizationFailed.failed = true
         }
         if (AuthorizationFailed.failed) {
            yield put(cutomiser.updateDriveError({ status: true, message: { head: "permission_error_head", body: "permission_error_body", defaultMessage: "Some permissions are not updated,please try again." } }));
         }
      }
   }


   if (putPayload.length > 0) {

      try {
         const response = yield all(putPayload.map((item) => {
            const response1 = Commonservice.putUserPermission(item)
            return response1
         }))
         const payload = { 'status': true, message: { body: "permission_body", defaultMessage: "Permission updated successfully", head: 'permission_head' } }
         yield put(cutomiser.updateToastDialogModal(payload));
      } catch (e) {
         AuthorizationFailed.failed = true
      }
      if (AuthorizationFailed.failed) {
         yield put(cutomiser.updateDriveError({ status: true, message: { head: "permission_error_head", body: "permission_error_body", defaultMessage: "Some permissions are not updated,please try again." } }));
      }
   }

}

function* updatedeleteFileFolderDriveOperation(action) {
   yield put(cutomiser.updateCommonLoader(true))
   try {
      const { driveSelectedItem, menuId, key } = action?.payload;
      const workpaceId = driveSelectedItem[0]?.original?.workspace?.id;
      const parentId = key === 'workspace' ? 0 : menuId;
      const finalResponse = { success: [], failure: [] };
      for (let i = 0; i < driveSelectedItem.length; i++) {
         const type = driveSelectedItem[i]?.original?.type;
         const itemId = driveSelectedItem[i]?.original?.id;
         const content = {
            id: driveSelectedItem[i]?.original?.id
         }
         try {
            const response = yield Commonservice.deleteFileFolderFromDrive(type, itemId, content)
            if (response.status !== 204) {
               if (response.status === 403) {
                  const payload = { 'status': true, message: { body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action.", head: 'Operation failed.' } }
                  yield put(cutomiser.updateToastDialogModal(payload));
               }
               finalResponse.failure.push(driveSelectedItem[i]);
            }
            if (response.status === 204) {
               finalResponse.success.push(driveSelectedItem[i]);
               
            }
         } catch (e) {
            const status = errorCode(e?.response?.status)
            finalResponse.failure =[...finalResponse.failure,{statusCode:status,name:driveSelectedItem[i]?.original?.name}]
            yield put(cutomiser.UpdateError({ status: true, statusCode: e?.response?.status }))
         }
      } //end for
      yield put(cutomiser.updateCommonLoader(false))
      yield put(cutomiser.updateDeletedItem(false))
      yield put(cutomiser.UpdateconfirmDialogStatus({
         'status': false
      }))
      if (finalResponse?.failure?.length > 0) {
         yield put(cutomiser.updateDeleteErrorModal({state:true,data:[...finalResponse.failure], modalHeader:"delete_op_failed"}))
      } else {
         const payload = { 'status': true, message: { body: "delete-success", defaultMessage: "delete-success", head: 'delete-success' } }
         yield put(cutomiser.updateToastDialogModal(payload));
      }

      //Removeselection for delted items
      yield put(cutomiser.UpdateDriveSelectedItem([]));
      const payloads = { key, menuId, types: "file,folder" };
      yield put(cutomiser.fetchDriveItemsRequest(payloads));
      const slice = yield select(getSliceData)
      const selectedportal = slice?.payload?.customizer?.selectedportal;
      /* Update leftside drive for updated items */
      yield put(cutomiser.updateNewDrive({workspaceId: workpaceId,folderId:parentId}))
   }
   catch (e) {
      console.log("Error!!", e)
   }
}

function* updatedrenameFileFolderDriveStateOperation(action) {
   const { type, itemId, content, menuId, key } = action?.payload
   const finalResponse = { failed: false }
   const selected = yield select(getSliceData)


   try {
      const response = yield Commonservice.renameFileFolderInDrive(type, itemId, content)

      if (response.status === 200) {
         const payload = { 'status': true, message: { body: "Rename_success", defaultMessage: "Name updated successfully", head: 'Rename' } }
         yield put(cutomiser.updateToastDialogModal(payload));
         yield put(cutomiser.updateDriveItemRename(false))
         const payloads = { key, menuId, types: "file,folder" };
         yield put(cutomiser.fetchDriveItemsRequest(payloads));
         const getVersion = selected?.payload?.customizer?.driveSelectedItem;
         const keys = Object.keys(getVersion[0])
         const x = {}
         keys.forEach(item => {
            if (item === "original") {
               const obj = {}
               const y = Object.keys(getVersion[0][item])
               y.forEach((itemy) => {
                  if (itemy === "name") {
                     obj[itemy] = content.name
                  } else {
                     obj[itemy] = getVersion[0][item][itemy]
                  }
                  x[item] = { ...obj }
               })
            } else {
               x[item] = getVersion[0][item]
            }
         })
         if (Object.keys(x).length > 0) {
            yield put(cutomiser.UpdateDriveSelectedItem([x]))
         }
         const parentId = key === 'workspace' ? 0: menuId;
         yield put(cutomiser.updateNewDrive({workspaceId: getVersion[0]?.original?.workspace?.id,folderId:parentId}))
         }
      }
   
   catch (e) {
      finalResponse.failed = true
         const error = errorCode(e?.response?.status)
         yield put(cutomiser.updateDriveError({
            status: true, message: {
               head: "Operation failed.", body: error, defaultMessage: "Network error, Please try again."
            }
         }));
   }
}

function* postRemoveSmartFolder(action) {
   try {
      const { id, workspaceId, type } = action?.payload
      const selected = yield select(getSliceData)
      const selectedPortal = selected?.payload?.customizer?.selectedportal?.cache[0]?.id
      try {
         yield Commonservice.deleteSmartFolderUrl(id)
         yield put(cutomiser.FetchWorkspacesRecursive(selectedPortal))
         const modalPayload = { 'status': true, message: { body: "smartfolder_deleted", defaultMessage: "Smart folder was deleted", head: 'Delete smart folder' } }
         yield put(cutomiser.updateToastDialogModal(modalPayload));
         yield put(cutomiser.updateFetchSmartFolderTrigger({
            type,
            id: workspaceId
         }))
         yield put(cutomiser.UpdateconfirmDialogStatus({ 'status': false }))
      }
      catch (e) {
         yield put(cutomiser.UpdateError({ status: true, statusCode: e?.response?.status, message: '', modalOpen: false }))
      }
   }
   catch (e) {
      yield put(cutomiser.UpdateError({ status: true, statusCode: e?.response?.status, message: '', modalOpen: false }))
   }
}

function* sendCheckDeleteState(action) {
   try {
      let response
      if (action.payload?.type === "folder") {
         response = yield Commonservice.getFolder(action.payload?.id)
      } else if (action.payload?.type === "file") {
         response = yield Commonservice.downloadFile(action.payload?.id, action.payload?.token)
      }
      if(response?.data?.is_trashed){
         yield put(cutomiser.updateDeleteState({ state: true }))      
      }else{
         yield put(cutomiser.updateDeleteState({ state: false }))
      }
   } catch (e) {
      yield put(cutomiser.updateDeleteState({ state: true }))
   }
}

function* dndFilePutServiceNew(action) {
   const slice = yield select(getSliceData)
   const { payload } = action;
   let driveUploadError = slice?.payload?.customizer?.driveUploadError;
   let driveUploadSuccess = slice?.payload?.customizer?.driveUploadSuccess;
   try {
      yield put(cutomiser.updateDriveLoading(true));
      const fileStore = slice?.payload?.customizer?.driveItems;

      const responsePut = yield Commonservice.updateDropFileProgress(payload, fileStore, payload?.onUploadProgress);
      driveUploadSuccess = [...driveUploadSuccess, { fileName: payload?.name }]
      yield put(cutomiser.UpdateDriveUploadSuccess(driveUploadSuccess))
      if (responsePut?.id) {
         const fetchPayload = { menuId: payload.id, types: "file,folder", key: payload.key };
         yield put(cutomiser.fetchDriveItemsRequest(fetchPayload));
         yield put(cutomiser.updateDriveLoading(false));
      }
   } catch (e) {
      yield put(cutomiser.updateDriveLoading(false));
      yield put(cutomiser.UpdateDriveUploadErrorSwitch(true))
      driveUploadError = [...driveUploadError, { fileName: payload?.name }]
      yield put(cutomiser.UpdatedriveUploadError([...driveUploadError]))
   }
   yield put(cutomiser.UpdatePopupOnSubmission(1))
}

function* signout(action) {
   try {
      yield put(cutomiser.AddDataToSwitchPortal(undefined));
      yield put(cutomiser.updateEmptyPortalAlert(false));
   }
   catch (e) {
      console.log(e)
   }
}


function* clearCache(action) {
   try {
      const { profileInfo, arr } = action.payload
      arr.forEach((item) => {
         const payload = { userid: profileInfo, cache: [], cacheName: item?.cacheName, method: "update" }
         ServerCache.putCache(profileInfo, payload, item.cacheName)
      })
      yield put(cutomiser.adminLeftMenuCacheonLoad([]))
      yield put(cutomiser.membersLeftMenuCacheonLoad([]))
      yield put(cutomiser.addDriveSidebarCache([]))
   }
   catch (e) {
      console.log(e)
   }
}

function* putNameConfigSmartFolder(action) {
   const { content, refetch, fileId } = action?.payload
   const { id, type } = refetch

   try {
      const response = yield Commonservice.configureNameSmartFolder(content, fileId)
      yield put(cutomiser.updateFetchSmartFolderTrigger({
         type, id
      }))

      yield put(cutomiser.updateGetNewSmartFolder({
         workspaceId : id
      }))

   }
   catch (e) {
      console.log(e, "Error")
      const error = errorCode(e?.response?.status)
      yield put(cutomiser.updateDriveError({
         status: true, message: {
            head: "Operation failed.", body: error, defaultMessage: error
         }
      }));

   }
}

function* portalPermissionsForLoggedUser(action) {
   try {
      const response = yield Commonservice.getLoggedUserPortalPermission(action?.payload);
      yield put(cutomiser.UpdateLoggedUserPortalPermissionState(response?.permission))
   }
   catch (e) {
      console.log("Error ", e.response)
   }
}

function* workspacePermissionsForLoggedUser(action) {
   try {
      const response = yield Commonservice.getLoggedUserWorkspacePermission(action.payload)
      yield put(cutomiser.UpdateLoggedUserWorkspacePermissionState(response))
   }
   catch (e) {
      console.log("Error ", e.response.status)
   }
}
function* postnewFolderWithoutRefetch(action) {
   try {
      const { workspaceId, parent } = action.payload
      const finalResponse = { failed: false }
      try {
         const response = yield Commonservice.createNewFileFolder(action.payload);
      } catch (e) {
         const error = errorCode(e?.response?.status)
         finalResponse.failed = true
      }
      if (finalResponse.failed) {
         yield put(cutomiser.updateDriveError({
            status: true, message: {
               head: "Operation failed.", body: "Unfortunately you do not have permission to perform this action.", defaultMessage: "Unfortunately you do not have permission to perform this action."
            }
         }))
      }
   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      console.log("error", error);
      yield put(cutomiser.UpdateError({ status: true, }))
   }
}
function fetchcustomDriveItemReq(action) {

   const { key, menuId } = action;
   const types = "file,folder";
   try {
      let responsePut;
      if (key === "workspace") {
         responsePut = Commonservice.getWorkspaceRootFolderChildren(menuId, types);
      } else if (key === "folder") {
         responsePut = Commonservice.getFolderAndFilesChildrenEndpoint(menuId);
      }
      return responsePut;
   } catch (e) {

      console.log('error', e)
      return e;
   }

}

function* updateDriveDragMove(action) {
   try {
      const { dropBoxData, portalId } = action.payload;
      if (dropBoxData) {
         const menuDetails = { menuId: dropBoxData.id, key: dropBoxData.key ? dropBoxData.key : 'folder' }
         const slice = yield select(getSliceData)
         const fileStore = yield fetchcustomDriveItemReq(menuDetails);
         const dragEventData = slice?.payload?.customizer?.DragEventData;
         const data = dragEventData?.driveSelectedItem;
         const menu = dragEventData?.menu;
         const breadcrubDrive = slice?.payload?.customizer?.breadcrubDrive;
         const currentWorkspace = breadcrubDrive && breadcrubDrive?.split('/')[2].split('<<')[1];
         const droppedworkspace = findWorkspaceDetails(dropBoxData.parent, dropBoxData.key ? 'workspace' : 'folder');
         const editOperation = { operation: 'cut' };
         const pasteValidation = droppedworkspace && droppedworkspace.workspaceId === currentWorkspace;
         if (dragEventData && dragEventData?.driveSelectedItem) {
            if (pasteValidation) { // validate on same workspace copy or cut
               yield put(cutomiser.updateDriveLoading(true));
               const response = { success: [], failure: [] };

               for (let i = 0; i < data.length; i++) {
                  let singleResp;
                  try {
                     singleResp = yield Commonservice.drivePasteOperation(data[i].original, fileStore, editOperation, menuDetails);
                     response.success.push(data[i]);

                  } catch (e) {
                     response.failure.push(data[i]);
                  }
               };
               if (response) {
                  const fetchPayload = { menuId: menu.menuId, types: "file,folder", key: menu.key };
                  yield put(cutomiser.fetchDriveItemsRequest(fetchPayload));
                  yield put(cutomiser.updateGetNewCacheData({portalId}))
                  if (response.failure.length > 0) {
                     const filesFailed = response.failure.map((item) => item.original.name);
                     const fileAsString = filesFailed.join(',');
                     yield put(cutomiser.updateDriveError({
                        status: true, message: {
                           head: "Move Operation Failed", body: `The operation could not be completed for the following file:
                     Permission denied for ${fileAsString}.`, defaultMessage: `The operation could not be completed for the following file:
                     Permission denied For ${fileAsString}.`
                        }
                     }));
                  }
                  yield put(cutomiser.UpdateDriveSelectedItem([]));
                  yield put(cutomiser.updateDriveLoading(false));
               }
            } else {
               yield put(cutomiser.updateDriveError({ status: true, message: { head: "Move Operation Failed", body: "Moving objects to different workspace not allowed", defaultMessage: "Move operation on different workspace not allowed" } }));
               yield put(cutomiser.updateDriveLoading(false));
            }
         } else {
            yield put(cutomiser.updateDriveError({ status: true, message: { head: "Move Operation Failed", body: "please select an item to move", defaultMessage: "please select an item to move" } }));
         }
         //else
      }


   }
   catch (e) {
      const error = errorCode(e?.response?.status)
      console.log("error", error);
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Edit Operation Failed", body: "Please try again", defaultMessage: "Please try again" } }));
      yield put(cutomiser.updateDriveLoading(true));
   }
}

function* fetchHighestFolderFilePrem(action) {
   const { folderId } = action.payload
   try {
      const response = yield Commonservice.highestFileFolderPremContent(folderId)
      if (response) {
         yield put(cutomiser.updateHighestFileFolderPrem(response?.data))
      }
   } catch (e) {
      console.log("e", e)
   }
}

function* fetchPrevFileprem(action){
   try{
      const response = yield Commonservice.highestFileFolderPremContent(action.payload)
      if (response) {
         yield put(cutomiser.updatePrevFileState(response?.data))
      }
   }
   catch(e){
      console.log(e)
   }
}


function* SelectedCityTriggerFunction(action) {
   let user = null
   try {
      const response = action.payload
      user = yield Commonservice.getProfileInfo();
      yield Commonservice.putCurrentCity(response,user?.id)
      yield put(cutomiser.updateCurrentWeatherLocation(response))
      const weatherData = yield Commonservice.loadWeatherInfo(action.payload,user?.locale.lang);
      yield put(cutomiser.UpdateCurrentWeather(weatherData))
   }
   catch (e) {
      console.log("Error!!")
   }
}

function* updateFavSmartFolderCacheRefetchItem(action) {
   try {
      const response = action.payload;
      const { userid, cacheName } = response;
      try{
         const serResp = yield ServerCache.putCache(userid, response, cacheName);
         yield put(cutomiser.ReadFavSmartFolderCache(serResp.data[0].cache));
      }
      catch(e){
         yield put(cutomiser.UpdateError({
            status: true,
            message: 'favorite_smartfolder_error',
            modalOpen: true
         }))
      }
         
   }
   catch (e) {
      console.log("Error!!")
   }
}

function* sendDriveTableHeadCache(action){
const {cacheName, userid, cache} = action.payload
try{
   const data = {
      cacheName:`${cacheName}`,
      userid,
      cache,
      key:`${userid}_${cacheName}`
   }
   const response  = yield ServerCache.postCache(data)
   if(response){
      yield put(cutomiser.fetchDriveTableHead({TableName:cacheName,id:userid}))
   }
}catch(e){
   console.log(e)
}
}

function* sendUpdateDriveTableHead(action){
   const {userid,cache,cacheName} = action.payload
   const cacheObj = {cache} 
   try{
   const response = yield ServerCache.putCache(userid,cacheObj,cacheName)
   if(response){
      yield put(cutomiser.fetchDriveTableHead({TableName:cacheName,id:userid}))
   }
}catch(e){
   console.log(e)
}

}

function* fetchDriveDataTableHead(action){
   const { id, TableName } = action.payload
   try{
      const responseData = yield ServerCache.getCacheWithName(TableName, id);
      yield put(cutomiser.getDriveTableheader(responseData?.cache))
   }catch(e){
      console.log(e)
   }
}

function* navigateToFiles(action){

   const {URL,rowInfo,navigate
      ,cacheName,profileInfo
   } = action.payload
  
   try {
      yield put(cutomiser.addDriveSidebarCache([]))
      const response = yield put(cutomiser.updateDriveSidebarCache({
         method:"update",
         cache:[],
         userid:profileInfo?.id,
         cacheName
      }))
      yield put(cutomiser.updateBreadcrubDrive(''))
      if(response?.payload?.cache?.length === 0){
            const x = rowInfo.original.path.split("+")
            const parentPath = x[0]
            navigate(`${URL}/${Number(rowInfo?.original?.id)}/${rowInfo?.original?.title}/folder?foldernavigation=ui&parent=${parentPath}`,{
            })
      }
   }
   catch(e){
      console.log("Error!!",e)
   }
}

function* navigateToSmartFolders(action){

   const {URL,profileInfo,cacheName,rowInfo,navigate} = action.payload
  
   try {
      yield put(cutomiser.addDriveSidebarCache([]))
      const response = yield put(cutomiser.updateDriveSidebarCache({
         method:"update",
         cache:[],
         userid:profileInfo?.id,
         cacheName
      }))
      yield put(cutomiser.updateBreadcrubDrive(''))
      if(response?.payload?.cache?.length === 0){
            navigate(`${URL}/${Number(rowInfo?.original?.id)}/smart_folder/${rowInfo?.original?.title}?smartfoldernavigation=ui`,{
            })
      }
   }
   catch(e){
      console.log("Error!!",e)
   }
}




function* updateDriveCaheHome(action){
   let serverResponse
   let serverPostResponse
   const response = action.payload;
   const { userid, cacheName, cache, key } = response;
   yield put(cutomiser.updateServerLoader(true))
   const responseCache = yield ServerCache.getCacheWithName(cacheName, userid);
   yield put(cutomiser.getDriveCache({ cacheName, userid }))
   try{
      if (responseCache?.cache[0]) {
          serverResponse = yield ServerCache.putCache(userid, response, cacheName);
      } else {
         serverPostResponse = yield ServerCache.postCache(response)
      }
      if(serverResponse){
         yield put(cutomiser.updateHomeNavigateLoader({cache:serverResponse?.data[0]?.cache}))
      }else{
         yield put(cutomiser.updateHomeNavigateLoader({cache:serverPostResponse?.data[0]?.cache[0]}))
      }
      yield put(cutomiser.updateServerLoader(false))
      
         
   }catch(e){
      yield put(cutomiser.updateServerLoader(false))
      console.log(e)
   }
}

function* checkAcceptInvitations(action){
   const {payload} = action.payload
   try{
      yield put(common.updateAcceptInvitationisLoading(true))
      const response = yield Commonservice.acceptInvite(payload)
      if(response){
         yield put(cutomiser.updateInviteSuccess(true))
      }
      yield put(common.updateAcceptInvitationisLoading(false))

   }catch(e){
      yield put(common.updateAcceptInvitationisLoading(false))
      console.log(e)
      yield put(cutomiser.updateInviteError(true))
   }
}

function* downLoadMultiple (action)  {
   const payload ={
      files : action.payload
   }
   try{
     const response =  yield Commonservice.multipleFileDownload(payload)
     
      downloadMultiple(response?.data?.Location)
   }
   catch(e){
      console.log("Error!!",e)
   }
}




function* chunkedUploadFunction (action) {
   /*eslint no-loop-func: "error"*/
   const {workspaceId,file,parentId,token,dispatch,comment,isDuplicate,fileId,menuId,key} = action.payload
   const chunkSize = 5242880;
   const accessTkn = token?.accessToken
   const slice = yield select(getSliceData)
   const fetchPayload = { menuId, types: "file,folder", key };

   async function chunkUpload(resp,dataArray,currentFile,offset,chunkCount){
      const totalCount = Math.floor(currentFile?.size / chunkSize)
      const before = new Date().getTime();
     
      const chunkFile = new Blob([dataArray.subarray(offset, (offset+chunkSize>currentFile.size?currentFile.size:offset+chunkSize))],
          { type: currentFile.type});
      await Commonservice.getUploadChunksEndpoint(resp?.id,chunkFile,offset).then(async (respTwo) => {
         offset = respTwo?.data?.offset
         if(offset < currentFile.size){
            if(respTwo?.status === 200 && chunkCount < totalCount){
               chunkCount+=1
               dispatch(cutomiser.updateChunkCount({chunkCount,totalCount}))
               chunkUpload(respTwo?.data,dataArray,currentFile,offset,chunkCount)
            }
         } else {
            const respthree =  await Commonservice.uploadChunkedEndApi(resp?.id,'',accessTkn,parentId,workspaceId,file,isDuplicate,fileId)

            const respFour =  await Commonservice.restrictedUploadFile(respthree?.data?.id)
            if(respFour.status === 200){
                dispatch(cutomiser.fetchDriveItemsRequest(fetchPayload))
            }
         }
      }).catch((e) =>{
       console.log("ERR",e)
      })
   }
   try {
      const resp = yield Commonservice.getUploadChunkedInitEndpoint(workspaceId,parentId,file)
    
         const offsetStart = resp.offset
         const fileReader = new FileReader()
         // eslint-disable-next-line func-names
         yield fileReader.onload = function (e){
            chunkUpload(resp,new Uint8Array(e.target.result),file,offsetStart,0)
         }
         yield fileReader.readAsArrayBuffer(file)
   }
   catch(e){
      console.log("e0",e)

   }
}

function* getFileLocationPath(action){
   let cacheResponse
   const slice = yield select(getSliceData)
   const {fileId,profileInfo,cacheName,type,navigate,smartFolderData} = action.payload
   if(type === 'Workspaces'){
      yield put(cutomiser.updateServerLoader(true))
   }
   else if(type === 'Smart folders'){
      yield put(cutomiser.updateHomesmartFolderLoader(true))
   }
  const driveSidebarCache = slice?.payload?.customizer.driveSidebarCache;
  const selectedPortal = slice?.payload?.customizer?.selectedportal

  const userid = profileInfo?.id
  let typeNo = null
  let breadcrumb
  switch(type){
   case 'Workspaces' : 
                        typeNo = 4
                        break;
   case 'Smart folders' : 
                           typeNo = 44
                           break;
   default : 
            typeNo = 4;
            break;
  }


  try{
     if(type === 'Workspaces'){
        const response = yield Commonservice.getfileLocationPathApi(fileId)
        breadcrumb = `/folder>>Workspaces<<${typeNo}${response?.data?.path}`
     }
     else if(type === 'Smart folders' && smartFolderData){
       breadcrumb = `/folder>>Smart folders<<${typeNo}/workspace>>${smartFolderData?.original?.workspace?.name}<<${smartFolderData?.original?.workspace?.id}/smart_folder>>${smartFolderData?.original?.folder?.name}<<${smartFolderData?.original?.folder?.id}`;
     }
       yield put(cutomiser.addDriveSidebarCache({
            cache: [breadcrumb],
             userid: profileInfo?.id,
             cacheName: `${cacheName}_${selectedPortal}`,
             key: `${profileInfo?.id}_${cacheName}_${selectedPortal}`,
           }))
     yield put(cutomiser.updateBreadcrubDrive(breadcrumb))
     if (driveSidebarCache?.cache?.length>0) {
      const payload = {cacheName,method:"update",cache:breadcrumb,userid}
   cacheResponse = yield ServerCache.putCache(userid, payload, cacheName);
   } else {
      const getCache = yield ServerCache.getCacheWithName(cacheName,userid)
      if(getCache?.cache?.length>0){
         const payload = {cacheName,method:"update",cache:breadcrumb,userid}
         cacheResponse = yield ServerCache.putCache(userid, payload, cacheName);
      }else{
      const payload ={key:`${userid}_${cacheName}`,method:"add",cache:breadcrumb,userid,cacheName}
       cacheResponse = yield ServerCache.postCache(payload)
      }
      
   }
      if(cacheResponse){
         navigate(`/user/drive/workspace`)
      }
      if(type === 'Workspaces'){
         yield put(cutomiser.updateServerLoader(false))
      }
      else if(type === 'Smart folders'){
         yield put(cutomiser.updateHomesmartFolderLoader(false))
      }
      yield put(cutomiser.updateServerLoader(false))
  }
  catch(e){
   if(type === 'Workspaces'){
      yield put(cutomiser.updateServerLoader(false))
   }
   else if(type === 'Smart folders'){
      yield put(cutomiser.updateHomesmartFolderLoader(false))
   }
   yield put(cutomiser.updateServerLoader(false))
   const error = errorCode(e?.response?.status)
   yield put(cutomiser.updateDriveError({
      status: true, message: {
         head: "Operation failed.", body: error, defaultMessage: error
      }
   }));
   console.log("Error",e)
  }
}

function* sendDelete (action) {
   const { fileType, id, workspaceId } = action.payload;
   let responsePut
   try{
      const response = yield Commonservice.deleteRecycleBinData(fileType, id)
      if(response){
          responsePut = yield Commonservice.getWorkspaceRootFolderChildren(workspaceId, "smart_folder");
          yield put(cutomiser.updateNewDrive({smartFolderId:workspaceId}))
      }
      if (responsePut?.entries) {
         yield put(cutomiser.updateFetchSmartFolder(responsePut));
      }
   }catch(e){
      const errorBody = errorCode(e.response.status)
      yield put(cutomiser.updateDriveError({ status: true, message: { head: "Rename workspace failed", body:errorBody, defaultMessage: "Error , Please try again" } }));
   }
}

function* getNewSmartFolder(action){
   const {workspaceId, portalId} = action.payload;
   const slice = yield select(getSliceData)
   let workpsacesSmartfolder = []
   const smartFolderNodes = slice?.payload?.customizer.smartFolderNodes;
   try{
      if(portalId && !workspaceId){
         const response = yield Commonservice.getPortalWorkspaces(portalId)
         workpsacesSmartfolder = response?.data?.entries?.map((item) => {
            return {
              id: item.id,
              type: 'smart_folder',
              key: `workspace`,
              name: item.name,
              url: '/user/drive/smartfolder',
              data: `Files/Workspaces/${item.name}`,
              icon: "Archive",
              pivot: `44/${item.id}`,
              Nodes: []
            }
          })
          const dataSet = sortNameArrayFilter(workpsacesSmartfolder)
         yield put(cutomiser.updateSmartFolderNodes([...dataSet]))
      }


      if(workspaceId && smartFolderNodes?.length > 0){
      const response = yield Commonservice.getWorkspaceRootSmartFolderChildren(workspaceId)
      workpsacesSmartfolder = yield response?.entries?.map((item) => {
         return {
           id: item.id,
           type: 'smart_folder',
           key: `smart_folder`,
           name: item.name,
           url: '/user/drive/smartfolder',
           data: `Files/Workspaces/${item.name}`,
           icon: "Archive",
           pivot: `44/${item.id}`,
           Nodes: []
         }
       })

       const treeData = 
       {
         id: 44,
         type: 'smartfolder',
         key: '',
         name: 'Smart folders',
         data: 'Files/Smart folders',
         pivot: '44',
         Nodes: [...smartFolderNodes],
         url: '/user/drive/workspace',
         root: true
       }

       const dataSet  = sortNameArrayFilter(workpsacesSmartfolder)
       const zaz =  makeObjectsTraversing(treeData,dataSet,workspaceId)

      yield put(cutomiser.updateSmartFolderNodes([...zaz]))
      }
   }
   catch(e){
      console.log(e)
   }

}

function* getNewDrive(action){
   const slice = yield select(getSliceData)
   const  driveDataOg = slice?.payload?.customizer?.driveData;
   const smartFolderNodes = slice?.payload?.customizer.smartFolderNodes;
   const driveData = [...driveDataOg]
   let workpsacesSmartfolder = []
   const breadcrubDrive = slice?.payload?.customizer?.breadcrubDrive
   const {portalId, workspaceId, folderId, smartFolderId} = action.payload;
try{
   if(smartFolderId){
      const response  = yield Commonservice.getWorkspaceRootSmartChildren(smartFolderId)
      const dataSet = sortNameArrayFilter([...response.entries])
      const smartFolderView = makeObjectsExtensible({Nodes:smartFolderNodes},smartFolderId,[...dataSet])
      yield put(cutomiser.updateSmartFolderNodes([...smartFolderView]))

   }
   if(portalId && !workspaceId && !folderId){
      const response = yield Commonservice.getWorkspacesNew(portalId)
      let workspaceData = response?.entries.map((item)=>{
         return {
            id: item.id,
            type: 'folder',
            key: "workspace",
            name: item.name,
            url: '/user/drive/workspace/workspacedetails',
            data: `Files/Workspaces/${item.name}`,
            icon: "Archive",
            pivot: `4/${item.id}`,
            Nodes: []
         }
      })
      workpsacesSmartfolder = response?.entries?.map((item) => {
         return {
           id: item.id,
           type: 'smart_folder',
           key: `workspace`,
           name: item.name,
           url: '/user/drive/smartfolder',
           data: `Files/Workspaces/${item.name}`,
           icon: "Archive",
           pivot: `44/${item.id}`,
           Nodes: []
         }
       })
       workspaceData = yield sortNameArrayFilter(workspaceData)
      workpsacesSmartfolder = yield sortNameArrayFilter(workpsacesSmartfolder)
      yield put(cutomiser.updateDriveData([...workspaceData]))
      yield put(cutomiser.updateSmartFolderNodes([...workpsacesSmartfolder]))
   }
   if(workspaceId && !folderId){
      const response = yield Commonservice.getWorkspaceRootFolderChildrenNew(workspaceId)
              const arr =  response.entries.map((itemRes)=>{return  {
               id: itemRes.id,
               entity_type:"folder",
               workspaceId,
               name: itemRes.name,
               children:itemRes.is_child,
               Nodes: []
            }})
            const dataSet = sortNameArrayFilter(arr)
            const fileFolder =yield makeObjectsExtensible({Nodes:driveData},workspaceId,dataSet)
      yield put(cutomiser.updateDriveData([...fileFolder]))
   }
   if(workspaceId && folderId){
      const folderchild = yield Commonservice.getFolderChildrenEndpointNew(folderId)
      const arrId = []
      yield breadcrubDrive.split("/").forEach((i)=>i && arrId.push(i.split("<<")[1]))
      arrId.shift()  
      const arr = folderchild.entries.map((itemRes)=>{return  {
         id: itemRes.id,
         entity_type:"folder",
         workspaceId,
         name: itemRes.name,
         children:itemRes.is_child,
         Nodes: []
      }})
      const treeData = 
         {
           id: 4,
           type: 'folder',
           key: '',
           name: 'Workspaces',
           data: 'Files/Workspaces',
           pivot: '4',
           Nodes: [...driveData],
           url: 'user/drive/workspace',
           root: true
         }
const dataSet = sortNameArrayFilter(arr)
const zaz = yield  makeObjectsExtensibleDynamic(treeData,arrId[arrId.length-1],dataSet,"id")
yield put(cutomiser.updateDriveData([...zaz]))
   }
}catch(e){
   console.log(e)
}
}

function* getDriveCacheData(action){
const {portalId} = action.payload
const slice = yield select(getSliceData)
const  driveSidebarCache = slice?.payload?.customizer?.driveSidebarCache;
const smartFolderNodes = slice?.payload?.customizer.smartFolderNodes;
const smartFolderCheck = driveSidebarCache.cache[0].split(">>")[1].split("<<")
const arrId = []
yield driveSidebarCache.cache[0] && driveSidebarCache.cache[0].split("/").forEach((i)=>i && arrId.push(i.split("<<")[1]))
let workspaeChildren = []
let porWorkspaceChildren = []
let workspaceData = []
let treeData = []
let workpsacesSmartfolder = []
   try{
      porWorkspaceChildren = yield Commonservice.getWorkspacesNew(portalId)
      if(porWorkspaceChildren.entries.length>0){
          workspaceData = porWorkspaceChildren?.entries.map((item)=>{
            return {
               id: item.id,
               type: 'folder',
               key: "workspace",
               name: item.name,
               url: '/user/drive/workspace/workspacedetails',
               data: `Files/Workspaces/${item.name}`,
               icon: "Archive",
               pivot: `4/${item.id}`,
               Nodes: []
            }
         })
         workpsacesSmartfolder = yield porWorkspaceChildren?.entries?.map((item) => {
            return {
              id: item.id,
              type: 'smart_folder',
              key: `workspace`,
              name: item.name,
              url: '/user/drive/smartfolder',
              data: `Files/Workspaces/${item.name}`,
              icon: "Archive",
              pivot: `44/${item.id}`,
              Nodes: []
            }
          })
          workspaceData  = yield sortNameArrayFilter(workspaceData)
          workpsacesSmartfolder  = yield sortNameArrayFilter(workpsacesSmartfolder)
      yield put(cutomiser.updateDriveData([...workspaceData]))
         yield put(cutomiser.updateSmartFolderNodes([...workpsacesSmartfolder]))
      }
      if(smartFolderCheck.includes("Smart folders")){
         const response  = yield Commonservice.getWorkspaceRootSmartChildren(arrId[1])
         const rebasedData =yield response.entries.map((item)=>{
            return{
               id: item.id,
               entity_type: 'smart_folder',
               key: `smart_folder`,
               name: item.name,
               url: '/user/drive/smartfolder',
               data: `Files/Workspaces/${item.name}`,
               icon: "Archive",
               pivot: `44/${item.id}`,
               Nodes: []
            }
         })
         const dataSet = sortNameArrayFilter(rebasedData)
         const smartFolderView = yield makeObjectsExtensible({Nodes:workpsacesSmartfolder},arrId[1],[...dataSet])
         yield put(cutomiser.updateSmartFolderNodes([...smartFolderView]))
      }else{   
       workspaeChildren = yield Commonservice.getWorkspaceRootFolderChildrenNew(arrId[1])
      if(workspaeChildren.entries){
      const arr = workspaeChildren.entries.map((itemRes)=>{return  {
         id: itemRes.id,
         entity_type:"folder",
         workspaceId:arrId[1],
         name: itemRes.name,
         children:itemRes.is_child,
         Nodes: []
      }})
      const dataSet = sortNameArrayFilter(arr)
       treeData  = makeObjectsExtensible({Nodes:workspaceData},arrId[1],[...dataSet])
      yield put(cutomiser.updateDriveData([...treeData]))
   }
   const workspaceId = arrId[1]
      arrId.splice(0,1)
      arrId.shift()
    // eslint-disable-next-line no-restricted-syntax
    for(const i of arrId){
       // eslint-disable-next-line no-loop-func
       /* eslint-disable no-loop-func */
          yield Commonservice.getFolderChildrenEndpointNew(i).then((data)=>{
             const arr = data.entries.map((itemRes)=>{return  {
               id: itemRes.id,
               entity_type:"folder",
               workspaceId,
               name: itemRes.name,
               Nodes: []
            }})
            const dataSet = sortNameArrayFilter(arr)
            treeData = makeObjectsExtensible({Nodes:treeData},i,[...dataSet])
            return treeData
         })
         yield put(cutomiser.updateDriveData([...treeData]))
      }
   }
}catch(e){

   console.log(e)
}
}

function*createFavFolderData(action){
   const{portalId,workspaceId,folderId} = action.payload
   const slice = yield select(getSliceData)
   const  newFavFolder = slice?.payload?.customizer?.newFavFolder;
   let porWorkspaceChildren = []
   let workspaceData = []
   let workspaeChildren = []
try{
   if(portalId && !folderId){
   porWorkspaceChildren  =yield Commonservice.getPortalWorkspaces(portalId)
   workspaceData = porWorkspaceChildren?.data?.entries.map((item)=>{
      return {
         workspaceid: item.id,
         workspacename: item.name,
         Nodes: []
      }
   })
   const dataSet = sortArray(workspaceData,"workspacename")
   yield put(cutomiser.updateNewFavFolder([...dataSet]))
}
if(workspaceId && !folderId){
   workspaeChildren =yield Commonservice.getWorkspaceRootFolderChildren(workspaceId,"folder")
   if(workspaeChildren.entries){
      const arr = workspaeChildren.entries.map((itemRes)=>{return  {
         id: itemRes.id,
         entity_type:"folder",
         parent:"",
         name: itemRes.name,
         workspaceOg:workspaceId,
         Nodes: []
      }})
      const rebasedData = sortNameArrayFilter(arr)
      const dataSet = makeObjectsExtensibleDynamic({Nodes:newFavFolder},workspaceId,rebasedData,"workspaceid")
      yield put(cutomiser.updateNewFavFolder(dataSet))
}
}
if(workspaceId && folderId){
 const folderData =   yield Commonservice.getFolderChildrenEndpoint(folderId)
 if(folderData.entries){
   const arr = folderData.entries.map((itemRes)=>{return  {
      id: itemRes?.id,
      entity_type:"folder",
      parent:itemRes?.parent?.id,
      name: itemRes?.name,
      workspaceOg:workspaceId,
      Nodes: []
   }})
   const rebasedData = sortNameArrayFilter(arr)
   const dataSet =yield makeObjectsExtensibleDynamic({Nodes:newFavFolder},folderId,rebasedData,"id")
   yield put(cutomiser.updateNewFavFolder(dataSet))
}
}
}catch(e){
   console.log(e)
}
}
function * favFolderCacheCheck(action){
   const slice = yield select(getSliceData)
   const  insertSmartFolderCache = slice?.payload?.customizer?.insertSmartFolderCache;
   const  profileInfo = slice?.payload?.customizer?.profileInfo;
   const{portalId,flag}= action.payload

   try{
      if(insertSmartFolderCache && insertSmartFolderCache[0]?.arr?.length>0){
         const response = yield Commonservice.checkFavFolderCache(insertSmartFolderCache[0].arr,portalId)
         if(response.data.length>0){
            yield put(cutomiser.updateRebasedFavSmart([{arr:response.data}]))
            if(insertSmartFolderCache[0].arr.length!==response?.data?.length){
               if(flag){
                  yield put(cutomiser.PutfavSmartFolderCacheItem(
                     {
                        userid: profileInfo.id,
                        cache: [
                            {
                                arr: [...response.data],
                            }
                        ],
                        cacheName: `${portalId}_${config.cacheFavoriteSmartFolder}`
                    }
                  ))
               }
            }
         }
      } else {
         yield put(cutomiser.updateRebasedFavSmart([{arr:[]}]))
      }
   }catch(e){
      console.log("favFolder check",e)
   }
}



// function* 
/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
function* mainSaga() {
   yield takeEvery("USER_FETCH_REQUESTED", fetchUser);
   yield takeEvery(cutomiser.ToggleMobileSidebar, fetchSidebar);
   yield takeEvery(cutomiser.UpdateCookiesAccept, cacheUpdateAcceptedCookies);
   yield takeEvery(cutomiser.UpdateDriverStarterPivot, cacheUpdateDriveSidebar);
   yield takeEvery(cutomiser.UpdateAdminStarterPivot, cacheUpdateAdminSidebar);
   yield takeEvery(cutomiser.BootsrapApp, bootrsapApp);
   yield takeEvery(cutomiser.Updateselectedportal, cacheUpdateselectedportal);
   yield takeEvery(cutomiser.UpdateCurrentLanguage, cacheUpdateselectedLanguage);
   yield takeEvery(cutomiser.FetchWorkspacesFromPortal, fetchWorkspacePortal);
   yield takeEvery(cutomiser.UpdateQuickViewSmartFolderTrigger, fetchQuickViewSmartFolders);
   yield takeEvery(cutomiser.UpdateQuickViewSmartFolders, addQuickViewSmartFolders);
   yield takeEvery(cutomiser.UdateSingleQuickViewSmartFolders, putQuickViewSmartFolders);
   yield takeEvery(cutomiser.UpdatePortalId, fetchPortalUsersEndpoint);
   yield takeEvery(cutomiser.UpdateTableHeaderDetails, fetchTableHeader);
   yield takeEvery(cutomiser.AddTableHeaderConfig, TableHeaderConfigStore);
   yield takeEvery(cutomiser.UpdateswitchPortaladdServerCache, switchPortalconfig);
   yield takeEvery(cutomiser.PutProfileInfo, Profileinfoload);
   yield takeEvery(cutomiser.UpdateTableHeaderConfig, putTableHeaderConfigConfiguration);
   yield takeEvery(cutomiser.getMembersData, fetchMembersData);
   yield takeEvery(cutomiser.getUsersMemberData, fetchUserDetailsForMembers);
   yield takeEvery(cutomiser.UpdateSwitchPortalCache, putSwitchPortalContent);
   yield takeEvery(cutomiser.getDashboardData, fetchgetHistoryEndpoint);
   yield takeEvery(cutomiser.UpdateMemberLeftMenu, fetchWorkspaceMenu);
   yield takeEvery(cutomiser.UpdateInvitation, fetchInvitationEndpoint);
   yield takeEvery(cutomiser.GetLabelData, fetchLabelData);
   yield takeEvery(cutomiser.fetchMenuAdminLeft, fetchAdminSidebarData)
   yield takeEvery(cutomiser.addMenuAdminLeft, PostMenuAdminLeft);
   yield takeEvery(cutomiser.updateadminLeftMenuCache, PutMenuAdminLeft);
   yield takeEvery(cutomiser.fetchMenuMembersLeft, FetchMemberSidebarCache);
   yield takeEvery(cutomiser.addMenuMembersLeft, AddMemberSidebarCache);
   yield takeEvery(cutomiser.updatemembersLeftMenuCache, PutMemberSidebarCache);
   yield takeEvery(cutomiser.GetAllowedClientData, FetchAllowedClients);
   yield takeEvery(cutomiser.fetchGroupData, GetAllGroupsData);
   yield takeEvery(cutomiser.GetHomeMemberData, fetchMemberHomeData);
   yield takeEvery(cutomiser.FetchSingleFolderDetail, GetFolderDetails);
   yield takeEvery(cutomiser.TriggerPutSingleAllowedClients, PutSingleAllowedClients);
   yield takeEvery(cutomiser.FetchSingleFileDetail, GetFileDetails);
   yield takeEvery(cutomiser.DeleteFromPortalMember, DeletePortalMember);
   yield takeEvery(cutomiser.UpdateProfileImage, updateProfilePhoto);
   yield takeEvery(cutomiser.FetchRecyclbinChildren, fetchRecyclbinChildren);
   yield takeEvery(cutomiser.FetchActivityData, fetchActivityEndpoint);
   yield takeEvery(cutomiser.AddfavSmartFolderCache, CreateFavSmartFolderCache);
   yield takeEvery(cutomiser.GetFavSmartFolderCache, getFavSmartFolderCacheItems);
   yield takeEvery(cutomiser.PutfavSmartFolderCacheItem, updateFavSmartFolderCacheItem)
   yield takeEvery(cutomiser.fetchWorkspaceGroup, getWorkspaceGroupList);
   yield takeEvery(cutomiser.AddInvite, postInviteMembers);
   yield takeLatest(cutomiser.updateDriveSidebarCache, putBreadcrubDriveCache);
   yield takeLatest(cutomiser.getDriveCache, fetchBreadcrubDrive);
   yield takeEvery(cutomiser.PostWorkSpace, sendWorkSpaceDetails);
   yield takeEvery(cutomiser.FetchWorkspacesRecursive, fetchWorkspacesRecursive);
   yield takeEvery(cutomiser.getLabel, getLabelItems);
   yield takeEvery(cutomiser.updateresendInvite, resendInvitation);
   yield takeEvery(cutomiser.DeleteFromInvitations, DeleteInvitations);
   yield takeEvery(cutomiser.postWorkSpaceCopy, workSpaceCopySent);
   yield takeEvery(cutomiser.sentRemoveWorkSpace, postRemoveWorkSpace);
   yield takeEvery(cutomiser.putRenameWorkSpace, sentRenameWorkSpace);
   yield takeEvery(cutomiser.addDefaultSync, postDefaultSyncStatus);
   yield takeEvery(cutomiser.updateWorkspacePermissionFunction, updateWorkspacePermission);
   yield takeEvery(cutomiser.addLabelTrigger, AddLabel)
   yield takeEvery(cutomiser.addUserWorkSpace, postUserWrkSpace);
   yield takeEvery(cutomiser.deleteUserFromWorkspace, DeleteUserFromWorkspace);
   yield takeEvery(cutomiser.updateLabelTrigger, UpdateLabel);
   yield takeEvery(cutomiser.updatePortalPermissionTrigger, updatePortalPermission);
   yield takeEvery(cutomiser.sentAddGroup, postGroup);
   yield takeEvery(cutomiser.updateMultipleAllowedClient, updateAllowedClientMultipleUsers);
   yield takeEvery(cutomiser.sendGetWorkspcMembers, fetchGetWorkSpaceMember);
   yield takeEvery(cutomiser.sentDeleteGroupMember, postDeleteGroupMember);
   yield takeEvery(cutomiser.createSmartFolderTrigger, createSmartFolderFunction);
   yield takeEvery(cutomiser.updateFetchSmartFolderTrigger, fetchSmartFolderFunction)
   yield takeEvery(cutomiser.updateRestoreItemFromRecyclebin, restoreItemFromRecycleBinFunction)
   yield takeEvery(cutomiser.updateLoggedUserPermissionTrigger, PermissionLoggedUser);
   yield takeEvery(cutomiser.getEmail, sentGetEmail)
   yield takeEvery(cutomiser.sentRemoveGroup, postRemoveGroup);
   yield takeEvery(cutomiser.deleteLabelTrigger, DeleteLabel);
   yield takeEvery(cutomiser.getGroupMembers, sentGetGroupMembers);
   yield takeEvery(cutomiser.sentSearchFile, fetchSearchFile);
   yield takeEvery(cutomiser.sentAddGrpMember, postGrpMember);
   yield takeEvery(cutomiser.dndFilePut, dndFilePutService);
   yield takeEvery(cutomiser.fetchDriveItemsRequest, fetchDriveItemsRequest);
   yield takeEvery(cutomiser.setEditGroupName, sentRenameGroup);
   yield takeEvery(cutomiser.clearRecycleBinTrigger, clearRecycleBinFunction);
   yield takeEvery(cutomiser.updateSmartFolderMetaFieldTrigger, smartFolderMetaFieldTriggerFunction)
   yield takeEvery(cutomiser.deleteRecyclebinRow, sentDeleteRecycleBinRow);
   yield takeEvery(cutomiser.configureSmartFolderTrigger, configureSMartFolderFunction);
   yield takeEvery(cutomiser.updateDeleteworkSpaceItems, sentDeleteWorkSpaceItems);
   yield takeEvery(cutomiser.UpdateDeleteSmartFolderTrigger, deleteSmartFolderFunction);
   yield takeEvery(cutomiser.updateFilePermission, getFilePermission);
   yield takeEvery(cutomiser.updateUserPermission, sentUserPermissions)
   yield takeEvery(cutomiser.sentUpdatedPermission, sentUpdateFilePermissions)
   yield takeEvery(cutomiser.UpdateDeleteSmartFolderTrigger, deleteSmartFolderFunction);
   yield takeLatest(cutomiser.Updatecreatemetafieldexistingsmartfolderstate, createNewmetaDataSmartFolder);
   yield takeEvery(cutomiser.UpdatelinkAcces, updatelinkAcces);
   yield takeEvery(cutomiser.DeleteMetaFieldSmartFolderTrigger, deleteMetaSmartFolderFunction)
   yield takeLatest(cutomiser.newFileFolder, postnewFolder)
   yield takeLatest(cutomiser.updateGetVersions, fetchVersions);
   yield takeEvery(cutomiser.sentFileComment, addFileComment)
   yield takeLatest(cutomiser.updateFilePreview, getFilePreview);
   yield takeEvery(cutomiser.updateFilePeviewLink, getViewId);
   yield takeLatest(cutomiser.updateFolderDownload, getFolderDownload);
   yield takeEvery(cutomiser.updatepostDriveLabelState, updatepostDriveLabelDataOPeration)
   yield takeEvery(cutomiser.updateputDriveLabelState, updateputDriveLabelStateOPeration)
   yield takeEvery(cutomiser.updatedeleteDriveLabelState, updatedeleteDriveLabelStateOperation)
   yield takeEvery(cutomiser.UpdatedriveSendLinkState, UpdatedriveSendLinkStateService)
   yield takeLatest(common.updateInitCut, drivePasteOperations);
   yield takeEvery(cutomiser.smartFolderMetaFieldTriggerForFilterCrieteriaTrigger, smartFolderMetaFieldTriggerForFilterCrieteriaTriggerFunction);
   yield takeEvery(cutomiser.updatefFleOnOfStatus, updatefFleOnOfStatus)
   yield takeEvery(cutomiser.updatedeleteFileFolderDriveState, updatedeleteFileFolderDriveOperation)
   yield takeEvery(cutomiser.updatedrenameFileFolderDriveState, updatedrenameFileFolderDriveStateOperation);
   yield takeEvery(cutomiser.updateDeleteSmartFolderState, postRemoveSmartFolder)
   yield takeEvery(cutomiser.updateCheckDeleteState, sendCheckDeleteState)
   yield takeEvery(cutomiser.dndFilePutNew, dndFilePutServiceNew)
   yield takeEvery(cutomiser.updateDeleteCache, clearCache)
   yield takeEvery(cutomiser.updateSignout, signout)
   yield takeEvery(cutomiser.UpdateSmartFolderNameStateTrigger, putNameConfigSmartFolder)
   yield takeLatest(cutomiser.UpdateLoggedUserPortalPermissionTrigger, portalPermissionsForLoggedUser)
   yield takeLatest(cutomiser.UpdateLoggedUserWorkspacePermissionTrigger, workspacePermissionsForLoggedUser)
   yield takeEvery(cutomiser.UpdateDriveDragMove, updateDriveDragMove)
   yield takeLatest(cutomiser.updateHighestFileFolder, fetchHighestFolderFilePrem)
   yield takeLatest(cutomiser.updateGetprevFileState,fetchPrevFileprem)
   yield takeLatest(cutomiser.UpdateSelectedCity,SelectedCityTriggerFunction)
   yield takeLatest(cutomiser.PutfavSmartFolderCacheRefetchItem,updateFavSmartFolderCacheRefetchItem)
   yield takeLatest(cutomiser.addDriveTableHead,sendDriveTableHeadCache)
   yield takeLatest(cutomiser.updateDriveTableHead,sendUpdateDriveTableHead)
   yield takeLatest(cutomiser.fetchDriveTableHead,fetchDriveDataTableHead)
   yield takeLatest(cutomiser.updateNavigateUrl,navigateToFiles)
   yield takeLatest(cutomiser.updateNavigateSmartFolders,navigateToSmartFolders)
   yield takeEvery(cutomiser.updateDriveSidebarCacheHome,updateDriveCaheHome)
   yield takeEvery(cutomiser.updateMultipleDownload,downLoadMultiple)
   yield takeEvery(cutomiser.updateAcceptInvitations,checkAcceptInvitations)
   yield takeEvery(cutomiser.updateretryRestoreItemFromRecyclebin, retryRestoreItemFromRecycleBinFunction)
   yield takeEvery(cutomiser.updateMultipleRestoreRetryRestoreAction,retryMultipleRestoreItemFromRecycleBinFunction)
   yield takeLatest(cutomiser.updateWeatherDataonLang,loadWeatherData)
   yield takeEvery(cutomiser.updateChunkedUploadState,chunkedUploadFunction)
   yield takeEvery(cutomiser.updateDeleteSmrtFolder,sendDelete)
   yield takeEvery(cutomiser.updateOpenFileLocationPath,getFileLocationPath)
   yield takeLatest(cutomiser.updateNewDrive, getNewDrive)
   yield takeLatest(cutomiser.updateGetNewCacheData, getDriveCacheData)
   yield takeLatest(cutomiser.updateNewFavFolderData,createFavFolderData)
   yield takeLatest(cutomiser.updateFavSmartCacheChange, favFolderCacheCheck)
   yield takeLatest(cutomiser.updateGetNewSmartFolder,getNewSmartFolder)
}  
export default mainSaga;

// driveItems